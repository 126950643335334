import {
  Grid,
  TextField,
  CircularProgress,
  Snackbar,
  Alert,
  Backdrop,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useState } from 'react'
import SubscribeCardAlike from '../../../HomeCompCont/SubscribeCard'
import { SaaSButton } from '../../../ThemeCust'
import { useData } from '../../../DataContext/DataContext'

const inputStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'yellow',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'yellow',
    },
  },
  textAlign: 'left',
}

const inputPropStyle = {
  style: {
    color: 'white',
    fontSize: '14px',
    fontWeight: '500',
  },
}

function AddCategory({ categoryCreated, category }) {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { categoryCreate, setCategories, fetchCategory } = useData()
  const [input, setInput] = useState({ name: '' })
  const [loading, setLoading] = useState(false)
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  })

  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value })
  }

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false })
  }

  const formSubmitHandler = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      // console.log('category input.name', input.name)
      const response = await categoryCreate(input.name)
      // console.log('serverMessage', response)
      if (response === 'Category created') {
        const newCategory = await fetchCategory()
        setCategories(newCategory.reverse())
        setSnackbar({
          open: true,
          message: 'Category Created',
          severity: 'success',
        })
        setInput({ name: '' })
        setTimeout(() => {
          categoryCreated(false)
          // console.log('Category tab switching', category)
        }, 3000)
      } else {
        // console.log('serverMessage in else', response)
        setSnackbar({
          open: true,
          message: `${response}`,

          severity: 'warning',
        })
      }
    } catch (error) {
      // console.log('Error creating category:', error.response.data.message)
      setLoading(false)
      if (
        error.response &&
        error.response.data.message === 'Category already exists'
      ) {
        setSnackbar({
          open: true,
          message: 'Category already exists',
          severity: 'warning',
        })
      } else {
        setSnackbar({
          open: true,
          message: 'Error creating category',
          severity: 'error',
        })
      }
    }
    setLoading(false)
  }

  return (
    <Box
      sx={{
        // p: 3,
        backgroundColor: '#262320',
        // display: 'flex',
        // justifyContent: 'center',
        // flexDirection: 'column',
      }}
    >
      <Box sx={{ display: 'flex', mt: -5, mb: 2 }}>
        <SubscribeCardAlike formOn={false} header={'Add category'} />
      </Box>
      <form onSubmit={formSubmitHandler}>
        <Grid
          container
          spacing={2}
          width={isSmallScreen ? '100%' : '100%'}
          paddingLeft={isSmallScreen ? 4 : 4}
          paddingRight={isSmallScreen ? 2 : 4}
          justifyContent={isSmallScreen ? 'center' : 'flex-start'} // Center on small screens, left align on larger
          marginTop={-7}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              name="name"
              value={input.name}
              placeholder="Enter Category name"
              type="text"
              variant="outlined"
              sx={inputStyle}
              InputProps={inputPropStyle}
              onChange={handleChange}
              disabled={loading}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', textAlign: 'left' }}>
            <SaaSButton
              type="submit"
              sx={{
                borderRadius: '10px',
                textTransform: 'none',
                fontWeight: 700,
                padding: '12px 24px',
                marginTop: '5px',
                marginBottom: '20px',
              }}
            >
              Submit
            </SaaSButton>
          </Grid>
        </Grid>
      </form>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default AddCategory
