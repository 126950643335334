import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardMedia,
  Grid,
  Paper,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SubscribeCardAlike from '../HomeCompCont/SubscribeCard'
import { SaaSButton } from '../ThemeCust'
import StyledLink from '../../utils/StyledLink'
import { MetaDescriptionSetter } from '../../utils/MetaDescriptionSetter'

const avaTarInside =
  'https://assets-global.website-files.com/5f0a4c1cde4cddac7d2ad3b4/5f0d1a41d22b58ec37f69536_004-id-card-p-500.png'

const avaTarHomepg =
  'https://assets-global.website-files.com/5f0a4c1cde4cddac7d2ad3b4/5f0d1a41cf7fb31ed9b1e129_031-trophy.png'

const allWebPckg =
  'https://assets-global.website-files.com/5f0a4c1cde4cddac7d2ad3b4/5f0d1a4204e1018ca9d4862a_022-target.png'

const cardStyle = {
  color: '#ffffff80',
  marginBottom: '10px',
  marginTop: '10px',
  '&:hover': {
    color: '#ffa32d',
  },
}

function CardAdvrtsie({ avatar, avtrBgColor, header, amount, text }) {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundColor: '#777777',
        padding: '25px',
        flexGrow: 1,
        height: '100%', // Ensure card takes full height
      }}
    >
      <Box
        sx={{
          width: '100px', // Adjust the size of the container as needed
          height: '80px', // Adjust the size of the container as needed
          borderRadius: '50%',
          backgroundColor: avtrBgColor,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '20px', // Add some space between the avatar and text
        }}
      >
        <img
          src={avatar}
          alt="Avatar"
          style={{
            width: '80%',
            height: '80%',
            borderRadius: '50%',
          }}
        />
      </Box>
      <Typography variant="h6" sx={cardStyle}>
        {header}
      </Typography>
      <Typography sx={cardStyle}>
        <span style={{ fontSize: '28px', fontWeight: '500' }}> ${amount}</span>
        <span
          style={{ fontSize: '0.9em', verticalAlign: 'sub', marginLeft: '5px' }}
        >
          / month
        </span>
      </Typography>
      <Typography sx={cardStyle}>{text}</Typography>
      <SaaSButton sx={{ borderRadius: '5px', margin: '20px 0 20px 0' }}>
        Buy Package
      </SaaSButton>
    </Card>
  )
}

function CardBuilder() {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
        <CardAdvrtsie
          avatar={avaTarInside}
          avtrBgColor={'#ffa32d'}
          header={'Inside Package'}
          amount={'360'}
          text={
            <>
              <p>1 sidebar banner (320x440px)</p>
              <p>All the internal post pages</p>
            </>
          }
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
        <CardAdvrtsie
          avatar={avaTarHomepg}
          avtrBgColor={'#06c25e'}
          header={'Home Page Package'}
          amount={'820'}
          text={
            <>
              <p>1 Home Top banner (1440x128px) and</p>
              <p>1 Home Bottom banner (728x90px)</p>
            </>
          }
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
        <CardAdvrtsie
          avatar={allWebPckg}
          avtrBgColor={'#5d66fe'}
          header={'All Website Package'}
          amount={'1250'}
          text={
            <>
              <p>All Banners on every page</p>
              <p>Home as well as Internal pages</p>
            </>
          }
        />
      </Grid>
    </Grid>
  )
}

export function ExpandablePanel({ headerText, text1, text2, text3 }) {
  return (
    <Accordion sx={{ backgroundColor: '#777777' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" sx={{ color: '#ffffff80' }}>
          {headerText}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography sx={{ color: '#ffffff80' }}>{text1}</Typography>
        <Typography sx={{ color: '#ffffff80' }}>{text2}</Typography>
        <Typography sx={{ color: '#ffffff80' }}>{text3}</Typography>
      </AccordionDetails>
    </Accordion>
  )
}

function AdvertiseWdUs() {
  return (
    <>
      <MetaDescriptionSetter pageName={'advertise with us'} />
      <Paper
        elevation={0}
        sx={{
          backgroundColor: '#262320',
          // padding: '40px',
          position: 'relative',
        }}
      >
        {/* Main Content with Advertise Header */}
        <Grid container>
          <Card sx={{ width: '100%', position: 'relative' }}>
            <CardMedia
              component="img"
              image="https://assets-global.website-files.com/5f0a4c1cde4cddac7d2ad3b4/5f0d1842cf63ccc11bf0aeb4_Advertise_With_Us-HeaderImage.jpg"
              sx={{
                height: '350px',
              }}
            />
            <Typography
              variant="h2"
              fontWeight={700}
              sx={{
                position: 'absolute',
                top: '60%',
                left: '5%',
                transform: 'translateY(-50%)', // Center vertically
                color: '#ffd400',
                textAlign: 'left',
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)',
                fontSize: { xs: '24px', sm: '32px', md: '40px' },
                padding: '0 10px', // Ensure text doesn't overflow on small screens
              }}
            >
              Advertise with us
            </Typography>
          </Card>
        </Grid>

        {/* Subscribe Card and FAQ Section */}
        <SubscribeCardAlike
          header={'Showcase your SaaS products'}
          text={
            <>
              Get in front of your peers, your prospects or even get viewed by
              Investors
              <p>
                as they browse SaaS Journal to find the latest happenings in the
                SaaS World
              </p>
            </>
          }
          formOn={false}
        />
        <Grid container>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <CardBuilder />
          </Grid>
          <Grid item xs={1} />
        </Grid>

        <Grid
          container
          spacing={0}
          marginTop={{ md: 3, xs: 1 }}
          paddingBottom={6}
          marginLeft={{ md: -2 }}
          sx={{
            paddingLeft: { xs: 6, md: 12 },
            paddingRight: { xs: 6, md: 12 },
          }}
        >
          <Grid item xs={12} md={4}>
            <SubscribeCardAlike
              header={'Frequently Asked Questions'}
              formOn={false}
            />
          </Grid>
          <Grid item xs={12} md={8} marginTop={{ xs: 0, md: 3 }}>
            <ExpandablePanel
              headerText={'How many people will see my ad?'}
              text1={
                'The SaaS industry is growing in leaps and bounds every year and the visibility of new SaaS platforms like SaaS Journal create a funnel for discovery, awareness, sales and investor relationship. While still in the nascent stages of our growth our numbers are increasing day by day so please reach out to us to get the most recents stats.'
              }
            />
            <ExpandablePanel
              headerText={'Who is your primary audience?'}
              text1={
                <>
                  <p>
                    Our primary audience is can be divided into four distinct
                    profiles
                  </p>
                  <ul>
                    <li>
                      Entrepreneurs and decision makers here to discover the
                      latest innovations in SaaS products
                    </li>
                    <li>
                      Consultants, Agencies and organizations looking for sales
                      partnerships and affiliate agreements
                    </li>
                    <li>
                      SaaS Sales leadership to understand the market and the
                      competition
                    </li>
                    <li>
                      Investors who want to keep a broad overview of the
                      industry they are tracking within SaaS
                    </li>
                  </ul>
                </>
              }
            />
            <ExpandablePanel
              headerText={'Any other channels other than the website?'}
              text1={
                <p>
                  As is the proven success model of SaaS industry we market
                  heavily on all known Social Media Channels and have customized
                  packages for Social Media, Content Marketing and community
                  engagement. Please get in touch with us using the{' '}
                  <StyledLink to="/contact-us">Contact Us</StyledLink> form in
                  case you need any help on any of these.
                </p>
              }
            />
            <ExpandablePanel
              headerText={'Do you have any discounts?'}
              text1={
                'We are open to hear about barter offers or a genuine need of discount.'
              }
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default AdvertiseWdUs
