import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useData } from '../../../DataContext/DataContext'
import { useEffect, useState } from 'react'
import DeleteButton from '../TableUtils/DeleteButton'
import Preview from '../TableUtils/PreviewButton'
import EditButton from '../TableUtils/EditButton'
import { Box, Paper, Button, CircularProgress } from '@mui/material'
import AddAuthor from './AddAuthor'
import { SaaSButton } from '../../../ThemeCust'
import axios from 'axios'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

const Authors = () => {
  const { authors, isLoading, setAuthors } = useData()
  const [rows, setRows] = useState([])
  const [addAuthor, setAddAuthor] = useState(false)
  const [approveProgress, setApproveProgress] = useState({})
  const [rejectProgress, setRejectProgress] = useState({})

  useEffect(() => {
    setRows(
      authors.map((author, index) => ({
        id: index + 1,
        serverId: author._id,
        name: author.name,
        image: author.imagelink,
        description: author.description,
        twitter: author.twitter,
        email: author.email,
        linkedin: author.linkedin,
        pending: author.pending,
      }))
    )
  }, [authors])

  const clickHandler = () => {
    setAddAuthor(!addAuthor)
  }
  const handleApproval = async (id, approve) => {
    if (approve) {
      setApproveProgress((prev) => ({ ...prev, [id]: true }))
    } else {
      setRejectProgress((prev) => ({ ...prev, [id]: true }))
    }

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/authors/approval`,
        { id, approve }
      )
      // console.log('response after approval', response.data.data)
      if (response.data.message === 'Author rejected and deleted') {
        setAuthors((prevAuthors) =>
          prevAuthors.filter((author) => author._id !== id)
        )
      } else {
        setAuthors(response.data.data)
      }
    } catch (error) {
      console.error('Error updating author approval:', error)
    } finally {
      if (approve) {
        setApproveProgress((prev) => ({ ...prev, [id]: false }))
      } else {
        setRejectProgress((prev) => ({ ...prev, [id]: false }))
      }
    }
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 20 },
    { field: 'name', headerName: 'Name', width: 150 },
    {
      field: 'image',
      headerName: 'Image',
      width: 100,
      renderCell: (params) => (
        <img src={params.row.image} alt="Author" style={{ width: '100%' }} />
      ),
    },
    { field: 'description', headerName: 'Description', width: 200 },
    {
      field: 'email',
      headerName: 'Email',
      disableColumnMenu: true,
      width: 95,
      headerAlign: 'center',
    },
    {
      field: 'twitter',
      headerName: 'Twitter',
      disableColumnMenu: true,
      width: 95,
      headerAlign: 'center',
    },
    {
      field: 'linkedin',
      headerName: 'LinkedIn',
      disableColumnMenu: true,
      width: 110,
      headerAlign: 'center',
    },
    {
      field: 'preview',
      headerName: 'Preview',
      disableColumnMenu: true,
      headerAlign: 'left',
      width: 80,
      renderCell: (params) => (
        <Preview card={false} author={true} previewValue={params.row} />
      ),
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 80,
      disableColumnMenu: true,
      headerAlign: 'left',
      renderCell: (params) => (
        <EditButton id={params.row.serverId} name="author" />
      ),
    },
    {
      field: 'delete',
      headerName: 'Delete',
      disableColumnMenu: true,
      headerAlign: 'left',
      width: 90,
      renderCell: (params) => (
        <DeleteButton id={params.row.serverId} name="author" />
      ),
    },
    {
      field: 'approved',
      headerName: 'Approved',
      disableColumnMenu: true,
      width: 150,
      renderCell: (params) => {
        const isApproveLoading = approveProgress[params.row.serverId]
        const isRejectLoading = rejectProgress[params.row.serverId]
        return params.row.pending ? (
          <>
            <Button
              onClick={() => handleApproval(params.row.serverId, true)}
              color="success"
              sx={{
                marginRight: 0.5,
                textTransform: 'none',
                ':hover': { backgroundColor: '#dde03d' },
              }}
              disabled={isApproveLoading || isRejectLoading}
            >
              {isApproveLoading ? <CircularProgress size={24} /> : 'Approve'}
            </Button>
            <Button
              onClick={() => handleApproval(params.row.serverId, false)}
              color="error"
              sx={{
                textTransform: 'none',
                ':hover': { backgroundColor: '#dde03d' },
              }}
              disabled={isApproveLoading || isRejectLoading}
            >
              {isRejectLoading ? <CircularProgress size={24} /> : 'Reject'}
            </Button>
          </>
        ) : (
          <Box>
            <CheckCircleIcon color="success" />
          </Box>
        )
      },
    },
  ]

  return (
    <Paper sx={{ height: 'auto', width: 'auto' }}>
      <Box
        sx={{
          backgroundColor: '#262320',
          display: 'flex',
          justifyContent: {
            lg: 'right',
            xs: 'left',
            md: 'right',
            sm: 'right',
          },
          paddingRight: '55px',
        }}
      >
        <SaaSButton
          onClick={clickHandler}
          sx={{
            borderRadius: '10px',
            textTransform: 'none',
            fontWeight: 700,
            padding: '12px 24px',
            marginLeft: 4,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          {addAuthor ? 'Show Authors' : 'Add Author'}
        </SaaSButton>
      </Box>
      {addAuthor ? (
        <AddAuthor authorCreated={setAddAuthor} author={addAuthor} />
      ) : (
        <Paper sx={{ width: 'auto' }}>
          <DataGrid
            loading={isLoading}
            rows={rows}
            columns={columns}
            // disableColumnMenu
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 7,
                },
              },
            }}
            pageSizeOptions={[7]}
          />
        </Paper>
      )}
    </Paper>
  )
}

export default Authors
