import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useData } from '../../../DataContext/DataContext'
import { useEffect, useState } from 'react'

import { Box, Paper } from '@mui/material'
import { SaaSButton } from '../../../ThemeCust'
import AddCategory from './AddCategory'
import DeleteButton from '../TableUtils/DeleteButton'

const Categories = () => {
  const { categories, isLoading } = useData()
  const [rows, setRows] = useState([])
  const [addCategory, setAddCategory] = useState(false)

  useEffect(() => {
    setRows(
      categories.map((category, index) => ({
        id: index + 1,
        serverId: category._id,
        name: category.name,
      }))
    )
  }, [categories])

  const clickHandler = () => {
    setAddCategory(!addCategory)
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'name', headerName: 'Name', width: 850 },
    // {
    //   field: 'edit',
    //   headerName: 'Edit',
    //   width: 60,
    //   renderCell: (params) => {
    //     return <EditButton id={params.row.serverId} />
    //   },
    // },
    {
      field: 'delete',
      headerName: 'Delete',
      width: 150,
      hideSortIcons: true,
      renderCell: (params) => (
        <DeleteButton id={params.row.serverId} name="category" />
      ),
    },
  ]

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#262320',
          display: 'flex',
          justifyContent: {
            lg: 'right',
            xs: 'left',
            md: 'right',
            sm: 'right',
          },
          paddingRight: '55px',
        }}
      >
        <SaaSButton
          onClick={clickHandler}
          sx={{
            borderRadius: '10px',
            textTransform: 'none',
            fontWeight: 700,
            padding: '12px 24px ',
            marginLeft: 4,
            marginTop: 2,
            marginBottom: 4,
          }}
        >
          {addCategory ? 'Show Categories' : 'Add Category'}
        </SaaSButton>
      </Box>
      {addCategory ? (
        <AddCategory categoryCreated={setAddCategory} category={addCategory} />
      ) : (
        <Paper sx={{ width: 'auto' }}>
          <DataGrid
            loading={isLoading}
            rows={rows}
            columns={columns}
            // disableColumnMenu
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 7,
                },
              },
            }}
            pageSizeOptions={[7]}
          />
        </Paper>
      )}
    </>
  )
}

export default Categories
