import { Box, Typography, CardActionArea, useMediaQuery } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useData } from '../../../DataContext/DataContext'
import { useTheme } from '@mui/material/styles'

function GenerateBox({ DashBoardContents }) {
  const navigate = useNavigate()
  const { setSelectedTab } = useData()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const clickHandler = (categoryName) => {
    if (categoryName === 'Categories') setSelectedTab('Categories')
    if (categoryName === 'Metadescriptions') setSelectedTab('Metadescriptions')
    if (categoryName === 'Enquiries') setSelectedTab('Enquiries')
    if (categoryName === 'Articles') setSelectedTab('View Articles')
    if (categoryName === 'Authors') setSelectedTab('Authors')
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap', // Wraps the boxes if needed
        margin: 1, // Margin around the container
        padding: 1, // Padding inside the container
        gap: 2, // Gap between the boxes
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: isSmallScreen ? 'column' : 'row',
          alignItems: 'center',
          justifyContent: isSmallScreen ? 'center' : 'flex-start',
          flexWrap: 'wrap',
          gap: 2,
        }}
      >
        {DashBoardContents.map((category, index) => (
          <CardActionArea
            onClick={() => clickHandler(category.name)}
            key={index}
            sx={{
              width: 240,
              height: 130,
              backgroundColor: '#777777', // Changed to a valid hex color
              border: '1px solid #ccc',
              borderRadius: '8px',
              boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 1, // Padding inside the box
              color: '#fff', // White text color
              fontWeight: 'bold',
              textAlign: 'center', // Center align text
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography variant="h4" component="div">
                  {category.placeholder}
                </Typography>
                <Typography variant="subtitle1" component="div">
                  {category.name}
                </Typography>
              </Box>
              <Box sx={{ marginLeft: 2 }}>{category.icon}</Box>
            </Box>
          </CardActionArea>
        ))}
      </Box>
    </Box>
  )
}

export default GenerateBox
