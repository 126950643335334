import { Paper } from '@mui/material'
import { Routes, Route } from 'react-router-dom'
// import NavDrawer from './NavDrawer/NavDrawer'
import '../src/App.css'

import Home from './Components/HomeCompCont/Home'
import AboutUs from './Components/AboutUsCont/AboutUs'
import AdvertiseWdUs from './Components/AdvertiseWdUsCont/AdvertiseWdUs'
import ContactUs from './Components/ContactUs/ContanctUs'
import Footer from './Components/Footer/Footer'
import NavDrawer from './NavDrawer/NavDrawer'
import LogIn from './Components/Admin/LogIn'
import { DataProvider } from './Components/DataContext/DataContext'
import AdminPanelV2 from './Components/Admin/AdminPanelV2'
import ArticleCreate from './Components/Admin/Table/Article/ArticleCreate'
import AuthorSpecificContent from './Components/AboutUsCont/AuthorSpecificContent'
import PageBuilder from './Components/PageBuilder/PageBuilder'
import CategorySpecificContent from './Components/PageBuilder/CategoryWise'
import useScrollToTop from './utils/UseScrollTop'
import AddAuthor from './Components/Admin/Table/Author/AddAuthor'
import PrivacyPolicy from './utils/PrivacyPolicy'
import Metadescriptions from './Components/Admin/Table/Metadescription/Metadescriptions'
import Categories from './Components/Admin/Table/Category/Categories'
import CategoryWiseArticle from './Components/PageBuilder/CategoryWiseArticle'
import Blogs from './Components/Blogs/Blogs'
import NavDrawer2 from './NavDrawer/NavDrawer2'
const navIndexArray = [
  { text: 'Home', route: '/' },
  { text: 'About Us', route: '/about-us' },
  { text: 'Blogs', route: '/blogs' },
  { text: 'Contact Us', route: '/contact-us' },
  { text: 'Advertise With Us', route: '/advertise-with-us' },
  // { text: 'Dashboard', route: '/admin-panel' },
  // { text: 'Promote Your SaaS', route: '/promote-your-saas' },
]
function App() {
  useScrollToTop()

  return (
    <DataProvider>
      <Paper elevation={0}>
        <NavDrawer2 links={navIndexArray} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/advertise-with-us" element={<AdvertiseWdUs />} />
          <Route path="/login" element={<LogIn />} />
          <Route path="/admin-panel" element={<AdminPanelV2 />} />
          <Route path="/card-create" element={<ArticleCreate />} />
          <Route path="/author-create" element={<AddAuthor />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/metadesc-create" element={<Metadescriptions />} />
          {/* <Route path="/author-content" element={<AuthorSpecificContent />} /> */}
          <Route
            path="/author-content/:authorName"
            element={<AuthorSpecificContent />}
          />
          {/* <Route path="/page/:id" element={<PageBuilder />} /> */}
          {/* <Route path="/page/:headertext" element={<PageBuilder />} /> */}
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blog/:slug" element={<PageBuilder />} />
          <Route
            path="/category/:categoryName"
            element={<CategoryWiseArticle />}
          />
          {/* <Route path="/categories" element={<Categories />} /> */}
        </Routes>
        <Footer />
      </Paper>
    </DataProvider>
  )
}

export default App
