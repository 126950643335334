import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useData } from '../../../DataContext/DataContext'
import { useEffect, useState } from 'react'
import { formatDate } from '../../../CardBuilder/DateFormat'
import DeleteButton from '../../Table/TableUtils/DeleteButton'
import Preview from '../../Table/TableUtils/PreviewButton'
import EditButton from '../../Table/TableUtils/EditButton'
import { Paper, TextField, Box } from '@mui/material'
import { inputContainerStyle, inputStyle } from '../../../ThemeCust'

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 60,
    disableColumnMenu: true,
    headerAlign: 'center',
  },
  { field: 'headerText', headerName: 'Header Text', width: 200 },
  { field: 'category', headerName: 'Category', width: 150 },
  { field: 'author', headerName: 'Author', width: 150 },
  {
    field: 'date',
    headerName: 'Date',
    width: 150,
    renderCell: (params) => <>{formatDate(params.row.date)}</>,
  },
  {
    field: 'image',
    headerName: 'Image',
    width: 150,
    disableColumnMenu: true,
    headerAlign: 'center',
    renderCell: (params) => (
      <img
        alt="cards-preview"
        src={params.row.image}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          padding: '1px',
        }}
      />
    ),
  },
  {
    field: 'preview',
    headerName: 'Preview',
    width: 90,
    disableColumnMenu: true,
    headerAlign: 'center',
    renderCell: (params) => <Preview previewValue={params.row} />,
  },
  {
    field: 'edit',
    headerName: 'Edit',
    width: 80,
    disableColumnMenu: true,
    headerAlign: 'center',
    renderCell: (params) => <EditButton id={params.row.serverId} name="card" />,
  },
  {
    field: 'delete',
    headerName: 'Delete',
    width: 90,
    disableColumnMenu: true,
    headerAlign: 'center',
    renderCell: (params) => (
      <DeleteButton id={params.row.serverId} name="article" />
    ),
  },
]

function ViewArticles() {
  const { cards, isLoading, cardCreated } = useData()
  const [rows, setRows] = useState([])
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    setRows(
      cards.map((card, index) => ({
        id: index + 1,
        serverId: card._id,
        headerText: card.headertext,
        category: card.category.name,
        author: card.author.name,
        date: card.date,
        image: card.imagelink,
        body: card.shordescription,
        slug: card.slug,
      }))
    )
  }, [cards, cardCreated])

  const handleSearch = (event) => {
    setSearchQuery(event.target.value)
  }

  const filteredRows = rows.filter(
    (row) =>
      row.headerText.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.author.toLowerCase().includes(searchQuery.toLowerCase())
  )

  return (
    <Paper sx={{ width: 'auto', padding: '16px' }}>
      <Box mb={2}>
        <TextField
          variant="outlined"
          fullWidth
          label="Search"
          value={searchQuery}
          onChange={handleSearch}
          sx={{
            ...inputStyle,
            '& label.Mui-focused': {
              color: 'black',
            },
          }}
          InputProps={inputContainerStyle}
        />
      </Box>
      <DataGrid
        loading={isLoading}
        rows={filteredRows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 7,
            },
          },
        }}
        pageSizeOptions={[7]}
      />
    </Paper>
  )
}

export default ViewArticles
