import {
  Card,
  CardContent,
  Typography,
  Snackbar,
  TextField,
  Box,
  Backdrop,
  CircularProgress,
  Alert,
} from '@mui/material'
import { useState } from 'react'
import { SaaSButton } from '../ThemeCust'
import { fetchEmailSubscription } from '../DataContext/DataService'

const inputStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'yellow',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'yellow',
    },
  },
  textAlign: 'center', // Centering text within TextField
}

const inputPropStyle = {
  color: 'black',
  fontSize: '14px',
  fontWeight: '700',
  padding: '5px 10px 5px 10px',
  backgroundColor: 'white',
  borderRadius: '15px',
  marginBottom: '10px',
}

function SubscribeForm({ inputFields }) {
  const [input, setInput] = useState('')
  const [loading, setLoading] = useState(false)
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setInput((prevState) => {
      return { ...prevState, [name]: value }
    })
  }
  const handleReset = () => {
    setInput({
      name: '',
      email: '',
    })
  }

  const submitHandler = async (e) => {
    e.preventDefault()
    setLoading(true)
    console.log('Form submitted with email:', input)
    try {
      const response = await fetchEmailSubscription(input)
      console.log('response from subscription', response)
      setSnackbar({
        open: true,
        message: response,
        severity: 'success',
      })
      handleReset()
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.message,
        severity: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false })
  }

  return (
    <>
      <form onSubmit={submitHandler}>
        <TextField
          value={input.name}
          name="name"
          placeholder="Your name"
          type="name"
          variant="standard"
          fullWidth
          InputProps={{
            disableUnderline: true,
            style: inputPropStyle,
          }}
          onChange={handleChange}
        />
        <TextField
          value={input.email}
          name="email"
          placeholder="Your email"
          type="email"
          variant="standard"
          fullWidth
          InputProps={{
            disableUnderline: true,
            style: inputPropStyle,
          }}
          onChange={handleChange}
        />
        <Box sx={{ display: 'flex', marginTop: '20px' }}>
          <SaaSButton
            type="submit"
            variant="outlined"
            sx={{
              width: '60%',
              color: 'white',
              borderRadius: '40px',
              backgroundColor: 'transparent',
              borderColor: 'white',
              borderWidth: '1px',
              margin: 'auto',
              textAlign: 'center',
              '&:hover': {
                borderColor: 'transparent',
              },
            }}
          >
            Submit
          </SaaSButton>
        </Box>
      </form>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  )
}

function SubscribeCardAlike({
  header,
  text,
  formOn = true,
  height = null,
  backgroundColor,
}) {
  return (
    <Card square={true} elevation={0}>
      <CardContent
        sx={{
          backgroundColor: backgroundColor ? backgroundColor : '#262320',
          paddingLeft: '30px',
          paddingRight: '30px',
        }}
      >
        <Typography
          sx={{
            color: '#ffd400',
            textAlign: 'center',
            fontSize: '24px',
            lineHeight: '26px',
            marginBottom: height ? height : '23px',
            padding: '17px 0 0',
            letterSpacing: '-0.02em',
            fontWeight: '700',
            textShadow: '1px 3px 1px rgba(0, 0, 0, 0.5)',
            textWrap: 'wrap',
          }}
          variant="h3"
        >
          {header}
        </Typography>
        <Typography
          sx={{
            color: '#ffffff80',
            lineHeight: '30px',
            margin: '0 0 15px',
            marginBlockStart: '1em',
            marginBlockEnd: '1em',
            marginInlineStart: '0px',
            marginInlineEnd: '0px',
            fontSize: '14px',
            textAlign: 'center',
          }}
        >
          {text}
        </Typography>
        {formOn && <SubscribeForm />}
      </CardContent>
    </Card>
  )
}

export default SubscribeCardAlike
