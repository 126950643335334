import React, { useState } from 'react'
import { Box, Tab, Tabs } from '@mui/material'
import { Link } from 'react-router-dom'
import { StyleDropDownfrmAppbr } from '../Components/ThemeCust'
import { useData } from '../Components/DataContext/DataContext'

function NavLinks({ links, orientationCust = 'horizontal' }) {
  const [currentTab, setCurrentTab] = useState(null)
  const { logged } = useData()

  // Filter the links to include "Dashboard" only if the user is logged in
  const filteredLinks = logged.state
    ? [...links, { text: 'Admin Panel', route: '/admin-panel' }]
    : links

  return (
    // <Box sx={StyleDropDownfrmAppbr}>
    <Tabs
      TabIndicatorProps={{
        style: {
          display: 'none', // Hide default underline
        },
      }}
      overflow="auto"
      textColor="white"
      value={currentTab}
      role="navigation"
      orientation={orientationCust}
      ml={{ xs: 3, sm: 0 }}
      mt={{ xs: 3, sm: 0 }}
      width={{ xs: '150px', sm: 'initial' }}
    >
      {filteredLinks.map((link, index) => (
        <Tab
          disableRipple
          key={index}
          label={link.text}
          component={Link}
          to={link.route}
          onMouseEnter={() => setCurrentTab(index)}
          onMouseLeave={() => setCurrentTab(-1)}
          sx={{
            fontWeight: { md: '300', lg: '400' },
            letterSpacing: '0.8',
            fontSize: { md: '14px', lg: '16px' },
            '&::after': {
              // for underline and
              content: '""',
              display: 'block',
              height: '2px',
              width: '100%',
              backgroundColor: '#dde03d', // Default underline color
              position: 'absolute',
              bottom: 0,
              left: 0,
              opacity: currentTab === index ? 1 : 0, // Show underline only on hover
              transition: 'opacity 0.3s ease-out', // Smooth transition
            },
          }}
        />
      ))}
    </Tabs>
    // </Box>
  )
}

export default NavLinks
