import React from 'react'
import { Drawer, IconButton, List } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import NavLinks from './NavLinks'

const listStyle = {
  width: '240px',
  '& .MuiPaper-elevation ': {
    backgroundColor: '#242424',
  },
}

function DrawerCmpnt({ links }) {
  const [openDrawer, setOpenDrawer] = React.useState(false)

  return (
    <React.Fragment>
      <Drawer
        sx={listStyle}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List sx={{ color: 'white' }}>
          <NavLinks links={links} orientationCust="vertical" />
        </List>
      </Drawer>

      <IconButton
        onClick={() => setOpenDrawer(!openDrawer)}
        sx={{ padding: 0 }} // Removed extra padding
      >
        <MenuIcon sx={{ fontSize: '40px', color: 'white' }} />
      </IconButton>
    </React.Fragment>
  )
}

export default DrawerCmpnt
