import {
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  LinearProgress,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { SaaSButton } from '../ThemeCust'
import styled from 'styled-components'
import { useData } from '../DataContext/DataContext'
import { fetchCardClick } from '../DataContext/DataService'

const CustomProgrssBar = styled(LinearProgress)`
  .MuiLinearProgress-barColorPrimary {
    background-color: #dde03d;
  }
`

export function CardArry({
  badge,
  headerText,
  backgrndImg,
  gridVal,
  cardIndex,
  handleHover,
  id,
  categoryId,
  slug,
}) {
  const navigate = useNavigate()

  const clickHandler = async (id, slug) => {
    await fetchCardClick(id)
    navigate(`/blog/${slug}`)
  }
  const clickToCategory = (categoryName) => {
    console.log('navigating to category1: ', categoryName)
    navigate(`/category/${categoryName.categoryName}`)
  }
  return (
    <Grid item xs={gridVal}>
      <Card
        className="custom-card"
        onMouseEnter={() => handleHover(cardIndex)}
        onClick={() => clickHandler(id, slug)}
        sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}
      >
        <CardContent
          sx={{
            backgroundImage: `url(${backgrndImg})`,
            color: 'white',
          }}
        >
          <SaaSButton
            onClick={(e) => {
              e.stopPropagation()
              clickToCategory({ categoryName: badge })
            }}
          >
            {badge}
          </SaaSButton>
          <CardMedia image={backgrndImg} />
          <Link
            underline="none"
            component="button"
            sx={{
              color: 'white',
              transition: 'color 0.2s ease-out',
              '&:hover': { color: '#dde03d' },
            }}
          >
            {backgrndImg !== null ? (
              <Typography
                sx={{
                  textAlign: 'left',
                  fontSize: { lg: '14px', md: '13px', sm: '12px' },
                  fontWeight: { lg: '600', md: '500', sm: '400' },
                  transition: 'color 0.8s ease-In-Out',
                  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
                }}
              >
                {headerText}
              </Typography>
            ) : (
              <></>
            )}
          </Link>
        </CardContent>
      </Card>
    </Grid>
  )
}

function HomeFrstRwFstCol() {
  const { cards } = useData()
  const navigate = useNavigate()
  const [activeCard, setActiveCard] = useState(null)
  const [loading, setLoading] = useState(true)
  const imgObjHom = cards.slice(0, 3)
  const theme = useTheme()
  const isScreenSm = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    if (imgObjHom.length) {
      const img = new Image()
      img.src = imgObjHom[0].imagelink
      img.onload = () => setLoading(false)
    }
  }, [imgObjHom])

  const handleHover = (index) => {
    setActiveCard(index)
  }

  const clickHandler = async (slug, id) => {
    // console.log('navigating to page: ', id)
    await fetchCardClick(id)
    navigate(`/blog/${slug}`)
  }

  const clickToCategory = (categoryName) => {
    console.log('navigating to category2: ', categoryName)
    navigate(`/category/${categoryName}`)
  }

  const progressWidth =
    activeCard !== null ? ((activeCard + 1) / imgObjHom.length) * 100 : 0

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '400px',
            width: '100%',
          }}
        >
          <CircularProgress size={44} />
        </Box>
      ) : (
        <CardMedia
          onClick={() =>
            clickHandler(
              imgObjHom[activeCard || 0].slug,
              imgObjHom[activeCard || 0]._id
            )
          }
          sx={{
            backgroundImage: `url(${
              activeCard === null
                ? imgObjHom[0].imagelink
                : imgObjHom[activeCard].imagelink
            })`,
            transition: 'background-image 0.8s ease-In-Out',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            color: '#dde03d',
            height: '100%',
            minHeight: { sm: '40vh' },
            position: 'relative',
            width: '100%',
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center', // Aligns content to the top
          }}
        >
          <CardContent>
            <Typography
              variant="h4"
              sx={{
                fontSize: { lg: '30px', md: '24px', sm: '20px' },
                fontWeight: '600',
                letterSpacing: '-0.06em',
                transition: 'color 0.8s ease-In-Out',
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
                mt: { lg: 20, sm: 10, md: 10 },
              }}
            >
              {activeCard === null
                ? imgObjHom[0].headertext
                : imgObjHom[activeCard]?.headertext}
            </Typography>

            <SaaSButton
              onClick={(e) => {
                e.stopPropagation()
                clickToCategory(
                  activeCard === null
                    ? imgObjHom[0].category.name
                    : imgObjHom[activeCard].category.name
                )
              }}
              sx={{ marginTop: 1, mb: 1 }} // Add margin if needed
            >
              {activeCard === null
                ? imgObjHom[0].category.name
                : imgObjHom[activeCard].category.name}
            </SaaSButton>
          </CardContent>
          {!isScreenSm && (
            <Grid container sx={{ position: 'absolute', bottom: '0' }}>
              <Grid item xs={12}>
                <CustomProgrssBar
                  variant="determinate"
                  value={progressWidth}
                  sx={{
                    position: 'relative',
                    width: '100%',
                  }}
                />
                <Grid container spacing={1}>
                  {imgObjHom?.map((item, index) => (
                    <CardArry
                      key={index}
                      badge={item.category.name}
                      headerText={item.headertext}
                      gridVal={4}
                      cardIndex={index}
                      handleHover={handleHover}
                      id={item._id}
                      slug={item.slug}
                    />
                  ))}
                </Grid>
              </Grid>
            </Grid>
          )}
        </CardMedia>
      )}
    </Card>
  )
}

export default HomeFrstRwFstCol
