import React, { useRef, useState } from 'react'
import {
  TextField,
  Grid,
  Box,
  Typography,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { SaaSButton } from '../../../ThemeCust'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import { fetchAuthors } from '../../../DataContext/DataService'
import { useData } from '../../../DataContext/DataContext'
import SubscribeCardAlike from '../../../HomeCompCont/SubscribeCard'

const inputStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'yellow',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'yellow',
    },
  },
  textAlign: 'left',
}

const inputPropStyle = {
  style: {
    color: 'white',
    fontSize: '14px',
    fontWeight: '500',
  },
}

function AddAuthor({ authorCreated, author }) {
  const location = useLocation()
  const { padding } = location.state || {}
  const { setAuthors } = useData()
  const [input, setInputs] = useState({})
  const [loading, setLoading] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
  const fileInputRef = useRef(null)

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleChange = (e) => {
    const { name, value, files } = e.target

    if (name === 'imagelink' && files.length > 0) {
      const selectedFile = files[0]
      const sanitizedFileName = selectedFile.name.replace(/\s+/g, '_')
      if (!selectedFile.type.startsWith('image/')) {
        alert('Please select an image file.')
        return
      }
      const sanitizedFile = new File([selectedFile], sanitizedFileName, {
        type: selectedFile.type,
      })
      setInputs((prevState) => ({
        ...prevState,
        [name]: sanitizedFile,
      }))
    } else {
      setInputs((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const formSubmitHandler = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      const formData = new FormData()
      Object.entries(input).forEach(([key, value]) => {
        formData.append(key, value)
      })
      // console.log('formData before sending-', formData)
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/authors`,
        formData
      )
      // console.log('response from author creation', response)
      setLoading(false)
      if (response.data.message === 'Author created') {
        setSnackbarSeverity('success')
        // console.log('author created')

        setSnackbarMessage('Author created successfully!')
        const newAuthor = await fetchAuthors()
        const reversedAuthors = newAuthor.reverse()
        setAuthors(reversedAuthors)
        setInputs({
          name: '',
          imagelink: null,
          description: '',
          linkedIn: '',
          twitter: '',
        })
        if (fileInputRef.current) {
          fileInputRef.current.value = null
        }

        setTimeout(() => {
          authorCreated(false)
          // console.log('Authors tab switching', author)
        }, 3000)
      } else {
        setSnackbarSeverity('warning')
        setSnackbarMessage(
          'Author creation response received, but the author might not have been created.'
        )
      }
      setSnackbarOpen(true)
    } catch (error) {
      console.log('Error creating author', error)
      setLoading(false)
      if (
        error.response &&
        error.response.data.message === 'Author already exists'
      ) {
        setSnackbarSeverity('warning')
        setSnackbarMessage(
          'Author already exists. Please use a different name.'
        )
      } else {
        setSnackbarSeverity('error')
        setSnackbarMessage('Error creating author. Please try again.')
      }
      setSnackbarOpen(true)
    }
  }

  return (
    <Box
      sx={{
        // p: 3,
        backgroundColor: '#262320',
        padding: padding ? padding : ' ',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start  ',
        flexDirection: 'column',
      }}
    >
      <Box marginTop={-3} marginBottom={1}>
        <SubscribeCardAlike
          formOn={false}
          header={'Add details for the author'}
        />
      </Box>
      <form onSubmit={formSubmitHandler}>
        <Grid
          container
          spacing={2}
          width={isSmallScreen ? '100%' : '80%'}
          paddingLeft={isSmallScreen ? 4 : 4}
          paddingRight={isSmallScreen ? 2 : 4}
          justifyContent={isSmallScreen ? 'center' : 'flex-start'} // Center on small screens, left align on larger
          marginTop={-7}
        >
          {/* <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'left',
              marginLeft: { xs: -6, sm: -4, md: -4 },
              marginBottom: -5,
            }}
          ></Grid> */}
          <Grid item xs={12} sm={6}>
            <TextField
              name="name"
              value={input.name}
              placeholder="Enter author's name"
              type="text"
              variant="outlined"
              sx={inputStyle}
              InputProps={inputPropStyle}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="imagelink"
              accept="image/*"
              type="file"
              variant="outlined"
              inputProps={inputPropStyle}
              onChange={handleChange}
              sx={inputStyle}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            {input.imagelink && (
              <Box sx={{ textAlign: 'center', marginTop: 2 }}>
                <Typography variant="subtitle1" sx={{ color: 'white' }}>
                  Image Preview:
                </Typography>
                <img
                  src={URL.createObjectURL(input.imagelink)}
                  alt="Preview"
                  style={{
                    maxHeight: isSmallScreen ? 150 : 250, // Responsive image height
                    maxWidth: '100%', // Ensure image does not overflow
                  }}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={6} mt={{ md: -2, xs: -1.5 }}>
            <TextField
              name="linkedIn"
              value={input.linkedIn}
              placeholder="Enter author's LinkedIn link"
              type="text"
              variant="outlined"
              sx={inputStyle}
              InputProps={inputPropStyle}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} mt={{ md: -2 }}>
            <TextField
              name="twitter"
              value={input.twitter}
              placeholder="Enter author's Twitter link"
              type="text"
              variant="outlined"
              sx={inputStyle}
              InputProps={inputPropStyle}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="email"
              value={input.email}
              placeholder="Enter author's email id"
              type="text"
              variant="outlined"
              sx={inputStyle}
              InputProps={inputPropStyle}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} mt={1}>
            <TextField
              name="description"
              value={input.description}
              placeholder="Type in author's description"
              type="text"
              variant="outlined"
              sx={{
                ...inputStyle,
                '& textarea': {
                  height: 'auto',
                  minHeight: '20px',
                },
              }}
              multiline
              rows={4}
              fullWidth
              InputProps={inputPropStyle}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', textAlign: 'left' }}>
            <SaaSButton
              type="submit"
              onSubmit={formSubmitHandler}
              sx={{
                borderRadius: '10px',
                textTransform: 'none',
                fontWeight: 700,
                padding: '12px 24px ',
                marginTop: '5px',
                marginBottom: '20px',
              }}
            >
              Submit
            </SaaSButton>
          </Grid>
        </Grid>
      </form>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default AddAuthor
