import React from 'react'
import { Typography, Card, CardContent, CardMedia } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { fetchCardClick } from '../DataContext/DataService'

function FeaturePostCreate({ imageUrl, headerText, cardId, slug }) {
  const navigate = useNavigate()
  const clickHandler = async (id, slug) => {
    await fetchCardClick(id)
    navigate(`/blog/${slug}`)
  }

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row', md: 'column', lg: 'row' },
        backgroundColor: 'grey',
        margin: '5px 0px',
        cursor: 'pointer',
        width: '100%',
      }}
    >
      {/* Image */}
      <CardMedia
        onClick={() => clickHandler(cardId, slug)}
        component="img"
        image={imageUrl}
        alt="Featured post image"
        sx={{
          objectFit: 'cover', // Adjusted to ensure the image scales correctly
          padding: 1,
          height: { xs: 150, sm: 120, md: 120, lg: 120 }, // Consistent height across small to medium screens
          width: { xs: '100%', sm: '40%', lg: 120, md: '100%' }, // Proper width for tablet screens
          borderRadius: '5%',
          transition: 'transform 0.5s ease',
          transform: 'translateX(0)',
          '&:active': {
            opacity: 0.5,
            transform: 'translateX(5px)',
          },
        }}
      />

      {/* Header Text */}
      <CardContent
        sx={{
          padding: { xs: 1, sm: 1, md: 2 },
          width: { xs: '100%', sm: '60%', md: 'auto' }, // Proper text width for tablet screens
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: 16, sm: 14, md: 12 }, // Adjust font size for tablet screens
            fontWeight: 700,
            color: '#ffd400',
            textAlign: 'left',
            whiteSpace: 'normal', // Prevent text from stretching
            overflow: 'hidden', // Prevent text overflow
            textOverflow: 'ellipsis', // Handle long text with ellipsis
          }}
        >
          {headerText}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default FeaturePostCreate
