import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Grid,
  Paper,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import SubscribeCardAlike from './SubscribeCard'
import HomeFrstRwFstCol from './HomeFrstRwFstCol'
import HomeFrstRowScndCol from './HomeFrstRowScndCol'
import FeaturedPost from './FeaturedPost'
import HomeScndRwFstColm from './HomeScndRwFstColm'
import { MetaDescriptionSetter } from '../../utils/MetaDescriptionSetter'

function Home() {
  const theme = useTheme()
  const isScreenMd = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      <MetaDescriptionSetter pageName="home" />
      <Paper
        className="main-container"
        elevation={0}
        sx={{
          marginRight: 'auto',
          marginLeft: 'auto',
          padding: { lg: '70px', md: '20px', sm: '10px' },
          marginTop: '25px',
          marginBottom: '30px',
        }}
      >
        <Grid>
          {!isScreenMd && (
            <Card>
              <CardActionArea sx={{ cursor: 'default' }}>
                <CardMedia
                  component="img"
                  image="https://assets-global.website-files.com/5f0a4c1cde4cddac7d2ad3b4/63984428ecdaebb9c7b4a6bc_NCJ%20qualetics%20ads.png"
                />
              </CardActionArea>
            </Card>
          )}
        </Grid>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'stretch',
            gap: 2, // Adjust spacing between items
            pt: 5,
          }}
        >
          {/* First component with 2/3 of the space */}
          <Box sx={{ flex: { xs: '1 1 100%', md: '2 1 0%' } }}>
            <HomeFrstRwFstCol />
          </Box>
          {/* Second component with 1/3 of the space */}
          <Box sx={{ flex: { xs: '1 1 100%', md: '1 1 0%' } }}>
            <HomeFrstRowScndCol />
          </Box>
        </Box>

        <Grid container spacing={4}>
          <Grid item md={8} xs={12}>
            <HomeScndRwFstColm />
          </Grid>
          {!isScreenMd && (
            <Grid item md={4} xs={0} marginTop={4}>
              <SubscribeCardAlike
                header="Subscribe"
                text="Get our latest posts and announcements in your inbox"
              />
              <Box mt={-3}>
                <SubscribeCardAlike
                  header="Featured articles"
                  text=""
                  formOn={false}
                  height={'0px'}
                />
              </Box>
              <FeaturedPost />
            </Grid>
          )}
        </Grid>
      </Paper>
    </>
  )
}

export default Home
