import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
} from '@mui/material'
import axios from 'axios'
import { useState, useEffect } from 'react'
import SubscribeCardAlike from '../../../HomeCompCont/SubscribeCard'
import { SaaSButton } from '../../../ThemeCust'
import { useData } from '../../../DataContext/DataContext'
import { Editor } from '@tinymce/tinymce-react'
import { handleEditorImageUpload } from '../../../../utils/imageUpload'

const initialValues = {
  badge: '',
  title: '',
  headertext: '',
  imagelink: null,
  body: '',
  author: '',
  date: '',
}

const inputStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  textAlign: 'left',
}

const inputPropStyle = {
  style: {
    color: 'white',
    fontSize: '14px',
    fontWeight: '500',
  },
}

function ArticleCreate() {
  const { setCardCreated, setSelectedTab, setCards, authors, categories } =
    useData()
  const [input, setInputs] = useState(initialValues)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [rawHtml, setRawHtml] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
  const [filteredAuthors, setFilteredAuthors] = useState([])

  useEffect(() => {
    // Filter approved authors
    const approvedAuthors = authors.filter((author) => !author.pending)
    setFilteredAuthors(approvedAuthors)
  }, [authors])

  const rawHtmlHandler = (e) => {
    setRawHtml(e.target.value)
  }

  const handleChange = (e) => {
    const { name, value, files } = e.target

    if (name === 'imagelink' && files.length > 0) {
      const selectedFile = files[0]
      const sanitizedFileName = selectedFile.name.replace(/\s+/g, '_')

      if (!selectedFile.type.startsWith('image/')) {
        alert('Please select an image file.')
        return
      }

      const sanitizedFile = new File([selectedFile], sanitizedFileName, {
        type: selectedFile.type,
      })
      setInputs((prevState) => ({
        ...prevState,
        [name]: sanitizedFile,
      }))
    } else {
      setInputs((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const formSubmitHandler = async (e) => {
    e.preventDefault()
    setLoading(true) // Start loading
    try {
      const formData = new FormData()
      Object.entries(input).forEach(([key, value]) => {
        formData.append(key, value)
      })
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/cards`,
        formData
      )
      // console.log('response', response)
      setLoading(false) // Stop loading
      if (response.data && response.data.message === 'Article created') {
        setInputs(initialValues)
        setSnackbarSeverity('success')
        setSnackbarMessage('Create Articled successfully')
        const cardsData = response && response.data.data.cards
        const reversedCardsData = cardsData.reverse()
        setCards(reversedCardsData)
        setInputs(initialValues)
        setCardCreated(true)
        setSnackbarOpen(true)
        setTimeout(() => {
          setSelectedTab('View Articles')
        }, 3000)
      }
    } catch (err) {
      // console.log('response', err)
      setLoading(false) // Stop loading
      setCardCreated(false)
      if (
        err.response &&
        err.response.data.message === 'Article already exists'
      ) {
        setErrorMessage('Article already exists. Please use a different title.')
      } else {
        setErrorMessage('Error creating article')
      }

      setError(true)
      setSnackbarSeverity('error')
      setSnackbarMessage(err.response ? err.response.data.message : err.message)
      setSnackbarOpen(true)
    }
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
    if (snackbarSeverity === 'success') {
      setSelectedTab('View Articles')
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        backgroundColor: '#262320',
      }}
    >
      <Paper
        elevation={0}
        sx={{
          backgroundColor: '#262320',
          padding: '30px',
          width: '100%',
          maxWidth: '800px',
          position: 'relative',
        }}
      >
        {loading && (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 10,
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <form onSubmit={formSubmitHandler}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'left',
                marginLeft: '-30px',
                marginBottom: -3,
                marginTop: -5,
              }}
            >
              <SubscribeCardAlike
                header="Enter details to create an article"
                formOn={false}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormControl
                variant="outlined"
                sx={{
                  ...inputStyle,
                  marginBottom: '20px',
                  '& .MuiInputLabel-root': {
                    color: 'white',
                  },
                  '& .MuiSelect-icon': {
                    color: 'white',
                  },
                  width: '48%',
                }}
              >
                <InputLabel sx={{ color: 'white' }}>Category</InputLabel>
                <Select
                  name="category"
                  value={input.category}
                  onChange={handleChange}
                  label="Category"
                  sx={{
                    '& .MuiSelect-select': {
                      color: 'white',
                    },
                    '& .MuiMenuItem-root': {
                      color: 'black',
                    },
                  }}
                  inputProps={{
                    style: { color: 'white' },
                  }}
                >
                  {categories.length > 0 ? (
                    categories.map((category) => (
                      <MenuItem key={category._id} value={category._id}>
                        {category.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>Loading categories...</MenuItem>
                  )}
                </Select>
              </FormControl>

              <FormControl
                variant="outlined"
                sx={{
                  ...inputStyle,
                  marginBottom: '20px',
                  '& .MuiInputLabel-root': {
                    color: 'white',
                  },
                  '& .MuiSelect-icon': {
                    color: 'white',
                  },
                  width: '48%',
                }}
              >
                <InputLabel sx={{ color: 'white' }}>Author</InputLabel>
                <Select
                  name="author"
                  value={input.author}
                  onChange={handleChange}
                  label="Author"
                  sx={{
                    '& .MuiSelect-select': {
                      color: 'white',
                    },
                    '& .MuiMenuItem-root': {
                      color: 'black',
                    },
                  }}
                  inputProps={{
                    style: { color: 'white' },
                  }}
                >
                  {filteredAuthors.map((author) => (
                    <MenuItem key={author._id} value={author._id}>
                      {author.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                name="headertext"
                value={input.headertext}
                placeholder="Enter headertext for the article"
                type="text"
                variant="outlined"
                sx={{
                  ...inputStyle,
                  marginBottom: '20px',
                  width: '100%',
                }}
                InputProps={inputPropStyle}
                onChange={handleChange}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                name="metadescription"
                value={input.metadescription}
                placeholder="Enter metadescription for the article"
                type="text"
                variant="outlined"
                sx={{
                  ...inputStyle,
                  marginBottom: '20px',
                  width: '100%',
                }}
                InputProps={inputPropStyle}
                onChange={handleChange}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '20px',
              }}
            >
              <TextField
                name="imagelink"
                placeholder="Upload an image"
                accept="image/*"
                type="file"
                variant="outlined"
                sx={{
                  ...inputStyle,
                  marginRight: '10px',
                  width: '48%',
                }}
                InputProps={inputPropStyle}
                onChange={handleChange}
              />
              {input.imagelink && (
                <img
                  src={URL.createObjectURL(input.imagelink)}
                  alt="Preview"
                  style={{ height: 100 }}
                />
              )}
            </Box>
            <TextField
              name="shortdescription"
              value={input.shortdescription}
              placeholder="Enter short description for article..."
              type="text"
              variant="outlined"
              sx={{
                ...inputStyle,
                marginBottom: '20px',
                width: '100%',
              }}
              InputProps={inputPropStyle}
              onChange={handleChange}
            />
            <Box>
              <TextField
                name="rawHtml"
                value={rawHtml}
                placeholder="Enter body for article..."
                type="text"
                variant="outlined"
                multiline
                rows={10}
                sx={{
                  ...inputStyle,
                  marginBottom: '20px',
                  width: '100%',
                }}
                InputProps={inputPropStyle}
                onChange={rawHtmlHandler}
              />
            </Box>

            <Editor
              apiKey="0db2zg1hl6crmc1q0rxtjcqqe5pgkjex5r5yx7q8v07e7nj2"
              initialValue={rawHtml || ''}
              init={{
                height: 500,
                selector: 'textarea',
                plugins:
                  'advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code wordcount accordion',
                toolbar:
                  'undo redo | styles |formatselect | bold italic  | forecolor backcolor emoticons | link | image |alignleft aligncenter alignright alignjustify |numlist bullist',

                menu: {
                  favs: {
                    title: 'My Favorites',
                    items: 'code visualaid | searchreplace | emoticons',
                  },
                },
                menubar:
                  'favs file edit view insert  lists format tools table help numlist bullist',

                forced_root_block: false,
                images_upload_handler: handleEditorImageUpload, // Use the handler for images upload
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; line-height:2 }',
              }}
              onEditorChange={(content) =>
                setInputs({ ...input, body: content })
              }
            />

            <SaaSButton
              type="submit"
              variant="contained"
              sx={{
                borderRadius: '10px',
                textTransform: 'none',
                fontWeight: 700,
                padding: '12px 24px',
                marginTop: '20px',
              }}
            >
              Create Article
            </SaaSButton>
          </Box>
        </form>
      </Paper>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default ArticleCreate
