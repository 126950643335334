import { useEffect } from 'react'
import { useData } from '../Components/DataContext/DataContext'

const getMetaDescription = (pageName, metaDescription) => {
  const normalisedPageName = pageName.toLowerCase()
  const pageMeta = metaDescription.find(
    (meta) => meta.name.toLowerCase() === normalisedPageName
  )
  return pageMeta ? pageMeta.metadescription : ''
}

export function MetaDescriptionSetter({
  pageName,
  article = false,
  articleMetaDesc,
}) {
  const { metaDescription } = useData()
  console.log('type of metaDescription', typeof metaDescription)

  useEffect(() => {
    const metaDescriptionContent = getMetaDescription(pageName, metaDescription)
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', metaDescriptionContent)
  }, [pageName, metaDescription])
  return null
}

export function MetadescriptionSetterForPage({ metaDescription }) {
  useEffect(() => {
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', metaDescription)
  }, [metaDescription])
  return null
}
