import React from 'react'
import { Container, Typography, Box } from '@mui/material'

function PrivacyPolicy() {
  return (
    <Container maxWidth="md" sx={{ mt: 12, mb: 5 }}>
      <Typography variant="h4" gutterBottom>
        Privacy Policy
      </Typography>
      <Box sx={{ mb: 2 }}>
        <Typography variant="body1" paragraph>
          Welcome to SaaS Journal. We respect your privacy and are committed to
          protecting your personal information. This privacy policy explains how
          we collect, use, and share your information.
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="h5" gutterBottom>
          Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We may collect the following types of information:
          <ul>
            <li>
              Personal identification information (Name, email address, phone
              number, etc.)
            </li>
            <li>Usage data (IP address, browser type, pages visited, etc.)</li>
            <li>Cookies and tracking technologies</li>
          </ul>
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="h5" gutterBottom>
          How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use the information we collect in the following ways:
          <ul>
            <li>To provide, operate, and maintain our services</li>
            <li>To improve, personalize, and expand our services</li>
            <li>
              To communicate with you, either directly or through one of our
              partners
            </li>
            <li>
              To send you updates and other information relating to the service
            </li>
            <li>To understand and analyze how you use our services</li>
          </ul>
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="h5" gutterBottom>
          Sharing Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We may share your information with third parties in the following
          circumstances:
          <ul>
            <li>With your consent</li>
            <li>For external processing by trusted partners</li>
            <li>For legal reasons</li>
            <li>
              To protect the rights, property, or safety of SaaS Journal, our
              users, or the public
            </li>
          </ul>
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="h5" gutterBottom>
          Your Data Protection Rights
        </Typography>
        <Typography variant="body1" paragraph>
          You have the right to:
          <ul>
            <li>
              Access – You have the right to request copies of your personal
              data.
            </li>
            <li>
              Rectification – You have the right to request that we correct any
              information you believe is inaccurate.
            </li>
            <li>
              Erasure – You have the right to request that we erase your
              personal data, under certain conditions.
            </li>
            <li>
              Restrict processing – You have the right to request that we
              restrict the processing of your personal data, under certain
              conditions.
            </li>
            <li>
              Object to processing – You have the right to object to our
              processing of your personal data, under certain conditions.
            </li>
            <li>
              Data portability – You have the right to request that we transfer
              the data that we have collected to another organization, or
              directly to you, under certain conditions.
            </li>
          </ul>
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="body1" paragraph>
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us at our
          provided contact details.
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="h5" gutterBottom>
          Cookies
        </Typography>
        <Typography variant="body1" paragraph>
          We use cookies and similar tracking technologies to track the activity
          on our Service and hold certain information.
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="h5" gutterBottom>
          Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="body1" paragraph>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="body1" paragraph>
          If you have any questions about this Privacy Policy, please contact
          us:
          <ul>
            <li>Email: privacy@saasjournal.com</li>
            <li>Phone: +1 --</li>
          </ul>
        </Typography>
      </Box>
    </Container>
  )
}

export default PrivacyPolicy
