import React from 'react'
import { Typography, Box } from '@mui/material'

export default function NoContentMessage() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '300px', // Adjust the height based on your layout
        textAlign: 'center',
      }}
    >
      <Typography
        variant="h6"
        sx={{
          color: '#888888', // Soft gray text color
          fontWeight: 500, // Medium font weight
          fontSize: '1.8rem', // Slightly larger font size
          '&::after': {
            content: '"."',
            animation: 'ellipsisAnimation 1.5s steps(4, end) infinite',
            display: 'inline-block',
            width: '1em',
            textAlign: 'left',
          },
          '@keyframes ellipsisAnimation': {
            '0%': {
              content: '"."',
            },
            '25%': {
              content: '".."',
            },
            '50%': {
              content: '"..."',
            },
            '75%': {
              content: '"...."',
            },
            '100%': {
              content: '"."',
            },
          },
        }}
      >
        No article added yet
      </Typography>
    </Box>
  )
}
