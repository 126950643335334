// StyledLink.jsx

import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

const StyledLink = styled(Link)`
  color: #ffd400;
  cursor: pointer;
  /* Add more styles as needed */
`;

export default StyledLink;
