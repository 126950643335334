import { Box, CircularProgress, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import GenerateBox from './GenerateBox'
import SubscribeCardAlike from '../../../HomeCompCont/SubscribeCard'
import { useData } from '../../../DataContext/DataContext'
import CategoryIcon from '@mui/icons-material/Category'
import PersonPinIcon from '@mui/icons-material/PersonPin'
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import DescriptionIcon from '@mui/icons-material/Description'
import PeopleIcon from '@mui/icons-material/People'
import axios from 'axios'
import { getVisitorsCount } from '../../../DataContext/DataService'

function DashBoard() {
  const { authors, categories, cards, enquiry, metaDescription } = useData()
  const [counts, setCounts] = useState('0')
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const DashBoardContents = [
    {
      name: 'Categories',
      placeholder: categories.length ? (
        categories.length
      ) : (
        <CircularProgress color="grey" />
      ),
      icon: <CategoryIcon sx={{ fontSize: 90 }} />,
    },
    {
      name: 'Authors',
      placeholder: authors.length ? (
        authors.length
      ) : (
        <CircularProgress color="grey" />
      ),
      icon: <PersonPinIcon sx={{ fontSize: 90 }} />,
    },
    {
      name: 'Articles',
      placeholder: cards.length ? (
        cards.length
      ) : (
        <CircularProgress color="grey" />
      ),
      icon: <ChromeReaderModeIcon sx={{ fontSize: 90 }} />,
    },
    {
      name: 'Enquiries',
      placeholder: enquiry.length ? (
        enquiry.length
      ) : (
        <CircularProgress color="grey" />
      ),
      icon: <HelpOutlineIcon sx={{ fontSize: 90 }} />,
    },
    {
      name: 'Metadescriptions',
      placeholder: metaDescription.length ? (
        metaDescription.length
      ) : (
        <CircularProgress color="grey" />
      ),
      icon: <DescriptionIcon sx={{ fontSize: 90 }} />,
    },
    {
      name: 'Total visitors',
      placeholder: counts ? counts : <CircularProgress color="grey" />,
      icon: <PeopleIcon sx={{ fontSize: 90 }} />,
    },
  ]

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: isSmallScreen ? 'center' : 'flex-start',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: isSmallScreen ? 'center' : 'flex-start',
          width: '100%',
        }}
      >
        <SubscribeCardAlike
          header={'Dashboard'}
          formOn={false}
          backgroundColor="#262320"
        />
      </Box>
      <Box
        sx={{
          marginTop: -5,
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <GenerateBox DashBoardContents={DashBoardContents} />
      </Box>
    </Box>
  )
}

export default DashBoard
