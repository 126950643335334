import { Box, Paper, Snackbar, TextField } from '@mui/material'
import axios from 'axios'
import { useState } from 'react'
import { SaaSButton } from '../ThemeCust'

const initialValues = {
  name: '',
  email: '',
  subject: '',
  message: '',
  response: '',
}

const inputStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'yellow',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'yellow',
    },
  },
  textAlign: 'center', // Centering text within TextField
}

const inputPropStyle = {
  style: {
    color: 'white',
    fontSize: '14px',
    fontWeight: '500',
  },
}

function SubscribeForm() {
  const [input, setInputs] = useState(initialValues)
  const [enquiryCreated, setEnquiryCreated] = useState(false)
  const [snackbarKey, setSnackbarKey] = useState(0)

  const handleChange = (e) => {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const formSubmitHandler = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/enquiry`,
        {
          name: input.name,
          email: input.email,
          subject: input.subject,
          message: input.message,
        }
      )
      setInputs((e) => {
        input.response = response
      })

      if (input.response.data.message === 'success') {
        setInputs(initialValues)
        setSnackbarKey((prevKey) => prevKey + 1)
        setEnquiryCreated(true)
      }
    } catch (err) {
      console.error('Error submitting enquiry:', err)
    }
  }

  const handleSnackbarClose = () => {
    setEnquiryCreated(false)
  }

  return (
    <Box sx={{ backgroundColor: '#262320' }}>
      <Paper
        sx={{
          backgroundColor: '#262320',
          padding: '20px',
          borderRadius: '8px',
          maxWidth: '800px',
          margin: '0 auto',
        }}
      >
        <form onSubmit={formSubmitHandler}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: 'space-between',
              width: '100%',
              marginBottom: '20px',
            }}
          >
            <TextField
              name="name"
              value={input.name}
              placeholder="Your Name"
              type="text"
              variant="outlined"
              sx={{
                ...inputStyle,
                width: { xs: '100%', sm: '48%' },
                marginBottom: { xs: '20px', sm: 0 },
              }}
              InputProps={inputPropStyle}
              onChange={handleChange}
            />
            <TextField
              name="email"
              value={input.email}
              placeholder="Your Email"
              type="email"
              variant="outlined"
              sx={{ ...inputStyle, width: { xs: '100%', sm: '48%' } }}
              InputProps={inputPropStyle}
              onChange={handleChange}
            />
          </Box>
          <Box
            sx={{
              width: '100%',
              marginBottom: '20px',
            }}
          >
            <TextField
              name="subject"
              value={input.subject}
              placeholder="Subject for message"
              type="text"
              variant="outlined"
              fullWidth
              sx={{ ...inputStyle, marginBottom: '20px' }}
              InputProps={inputPropStyle}
              onChange={handleChange}
            />
            <TextField
              name="message"
              value={input.message}
              placeholder="Type your message"
              type="text"
              variant="outlined"
              fullWidth
              multiline
              rows={5}
              sx={{
                ...inputStyle,
                '& textarea': {
                  height: 'auto',
                  minHeight: '30px',
                },
                marginBottom: '20px',
              }}
              InputProps={inputPropStyle}
              onChange={handleChange}
            />
            {enquiryCreated && (
              <Snackbar
                key={snackbarKey}
                open={enquiryCreated}
                autoHideDuration={2000}
                onClose={handleSnackbarClose}
                message="Enquiry Created"
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              />
            )}
            {!enquiryCreated && (
              <Box sx={{ textAlign: 'center' }}>
                <SaaSButton
                  type="submit"
                  onSubmit={formSubmitHandler}
                  sx={{
                    borderRadius: '10px',
                    textTransform: 'none',
                    fontWeight: 700,
                    padding: '12px 24px',
                    marginBottom: '20px',
                    backgroundColor: 'yellow', // Button color
                    color: 'black',
                    '&:hover': {
                      backgroundColor: 'darkorange', // Hover effect
                    },
                  }}
                >
                  Submit
                </SaaSButton>
              </Box>
            )}
          </Box>
        </form>
      </Paper>
    </Box>
  )
}

export default SubscribeForm
