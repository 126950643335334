import React, { useState, useMemo, useCallback, useRef } from 'react'
import {
  Box,
  CircularProgress,
  Grid,
  LinearProgress,
  Pagination,
  Stack,
} from '@mui/material'
import { useData } from '../DataContext/DataContext'
import { CardBuidlerV2 } from '../CardBuilder/CardBuilderV2'
import SubscribeCard from '../HomeCompCont/SubscribeForm'

const CARDS_PER_PAGE = 6
const START_INDEX = 0

function HomeScndRwFstColm() {
  const { cards } = useData()
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(false)

  // Create a ref to the blog section
  const blogSectionRef = useRef(null)

  const handlePageChange = useCallback((event, value) => {
    setLoading(true)
    setCurrentPage(value)

    setTimeout(() => {
      setLoading(false)
      if (blogSectionRef.current) {
        const offsetTop = blogSectionRef.current.offsetTop
        window.scrollTo({
          top: offsetTop,
          behavior: 'smooth',
        })
      }
    }, 400) // small delay to show spinner
  }, [])

  // Memoized calculation of current cards
  const currentCards = useMemo(() => {
    const indexOfLastCard = START_INDEX + currentPage * CARDS_PER_PAGE
    const indexOfFirstCard = indexOfLastCard - CARDS_PER_PAGE
    return cards.slice(indexOfFirstCard, indexOfLastCard)
  }, [currentPage, cards])

  if (cards.length <= START_INDEX) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          minHeight: '400px',
          marginTop: '200px',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  // Calculate progress percentage based on the current page
  const totalPages = Math.ceil((cards.length - START_INDEX) / CARDS_PER_PAGE)
  const progress = (currentPage / totalPages) * 100

  return (
    <Box
      marginTop={9}
      sx={{
        flexGrow: 1,
        p: { xs: 2, sm: 3, md: 4, lg: 8, xl: 10 },
        backgroundColor: '#262320',
      }}
    >
      <Box marginTop={-3} marginBottom={3} ref={blogSectionRef}>
        <SubscribeCard
          header={'Blogs'}
          fontSize={40}
          backgroundColor={'#262320'}
        />
      </Box>

      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '400px',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          <LinearProgress
            variant="determinate"
            value={progress} // Dynamic progress based on current page
            sx={{
              marginTop: 1,
              marginLeft: 2,
              width: '100%',
              backgroundColor: 'smokey-white',
              height: '4px',
              borderRadius: '5px',
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#dde03d',
                borderRadius: '5px',
              },
            }}
          />

          {currentCards.map((card, index) => (
            <Grid key={index} item md={6} sm={6} xs={12} lg={4}>
              <CardBuidlerV2
                cardImgUrl={card.imagelink}
                cardHeader={card.headertext}
                cardDate={card.date}
                cardText={card.shortdescription}
                cardBadge={card.category.name}
                truncate={true}
                cardImageWidth="100%"
                cardImageHeight={200}
                truncateValue={100}
                cardId={card._id}
                slug={card.slug}
                cardHeight={400}
                cardWidth={'100%'}
                elevation={2}
              />
            </Grid>
          ))}
        </Grid>
      )}

      <Box
        sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
      >
        <Stack spacing={2}>
          <Pagination
            count={Math.ceil((cards.length - START_INDEX) / CARDS_PER_PAGE)}
            page={currentPage}
            onChange={handlePageChange}
            shape="rounded"
            variant="outlined"
            sx={{
              '& .MuiPaginationItem-root': {
                color: 'white',
                borderColor: 'white',
              },
              '& .Mui-selected': {
                backgroundColor: 'white',
                color: 'black',
              },
            }}
          />
        </Stack>
      </Box>
    </Box>
  )
}

export default HomeScndRwFstColm
