import { useEffect } from 'react'
import { useData } from '../DataContext/DataContext'

const getRandomLightColor = () => {
  const letters = 'BCFFB' // Ensuring light colors with limited letters
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * letters.length)]
  }
  return color
}

export const CategoryGrid = () => {
  const { categories, setCategories } = useData()

  useEffect(() => {
    // Check if colors are already stored in local storage
    const storedColors = JSON.parse(localStorage.getItem('categoryColors'))

    // If stored colors exist and match the current categories length, apply them
    if (storedColors && storedColors.length === categories.length) {
      const updatedCategories = categories.map((category, index) => ({
        ...category,
        color: storedColors[index],
      }))

      // Update categories only if colors are not already set
      const areColorsSet = categories.every(
        (category, index) => category.color === storedColors[index]
      )
      if (!areColorsSet) {
        setCategories(updatedCategories)
      }
    } else {
      // Assign new colors and save them to local storage
      const newCategories = categories.map((category) => ({
        ...category,
        color: getRandomLightColor(),
      }))

      setCategories(newCategories)

      // Save the new colors to local storage
      const colorsToStore = newCategories.map((category) => category.color)
      localStorage.setItem('categoryColors', JSON.stringify(colorsToStore))
    }
  }, [categories, setCategories])

  return null // This component doesn't need to render anything
}
