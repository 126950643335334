import React, { useState } from 'react'
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
  CircularProgress,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { truncateText } from './TextTruncate'
import { SaaSButton } from '../ThemeCust'
import { formatDate } from './DateFormat'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { fetchCardClick } from '../DataContext/DataService'

export function CardBuidlerV2({
  cardImgUrl = '',
  cardHeader = '',
  cardText = '',
  cardBadge = '',
  cardAuthor = '',
  cardId = '',
  truncate = true,
  truncateValue = 0,
  cardContentDisplay = '',
  cardDate = '',
  animation = true,
  cardImageWidth,
  cardImageHeight,
  cardHeight,
  cardWidth,
  editOn = false,
  slug = '',
  elevation = 0,
  backgroundColor,
}) {
  const [loading, setLoading] = useState(true) // State to manage loading
  const theme = useTheme()
  const navigate = useNavigate()
  const isBelowLG = useMediaQuery(theme.breakpoints.down('lg'))

  const clickHandler = async (id, slug) => {
    await fetchCardClick(id)
    navigate(`/blog/${slug}`)
  }

  const clickToCategory = (categoryName) => {
    navigate(`/category/${categoryName}`)
  }

  return (
    <Card
      elevation={elevation}
      sx={{
        marginBottom: '20px',
        height: cardHeight,
        width: cardWidth,
        backgroundColor: backgroundColor || '',
      }}
    >
      <CardActionArea
        onClick={() => clickHandler(cardId, slug)}
        sx={{
          display: cardContentDisplay,
          flexDirection: isBelowLG ? 'column' : 'row',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: cardImageWidth,
            height: cardImageHeight || '260px',
          }}
        >
          {loading && (
            <CircularProgress
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 1,
              }}
            />
          )}
          <CardMedia
            component="img"
            image={cardImgUrl}
            style={{
              width: cardImageWidth ? cardImageWidth : '100%',
              minWidth: cardImageWidth ? cardImageWidth : '100%',
              height: cardImageHeight ? cardImageHeight : '260px',
              minHeight: cardImageHeight ? cardImageHeight : '',
              objectFit: 'cover',
              transition: 'transform 0.5s ease',
              transform: 'translateX(0)',
              display: loading ? 'none' : 'block', // Hide image while loading
            }}
            onLoad={() => setLoading(false)} // Image has loaded
            onError={() => setLoading(false)} // Handle error case
            {...(animation && {
              onMouseEnter: (e) => {
                e.currentTarget.style.transform = 'translateX(5px)'
              },
              onMouseLeave: (e) => {
                e.currentTarget.style.transform = 'translateX(0)'
              },
            })}
          />
        </Box>
        <CardContent>
          <CardHeader
            title={cardHeader}
            sx={{ padding: '0px', margin: '0px' }}
          />

          <Card
            elevation={0}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <SaaSButton
              variant="outlined"
              onClick={(e) => {
                e.stopPropagation()
                clickToCategory(cardBadge)
              }}
              sx={{
                fontSize: { lg: '10px', md: '8px' },
                padding: '5px',
                borderRadius: '25px',
                backgroundColor: 'white',
                borderColor: '#777777',
                borderWidth: '1px',
                '&:hover': {
                  borderColor: 'white',
                },
              }}
            >
              {cardBadge}
            </SaaSButton>
            <CalendarTodayIcon
              sx={{ fontSize: '12px', margin: '0 5px 0 5px' }}
            />
            <Typography sx={{ fontSize: '12px', color: '#777777' }}>
              {formatDate(cardDate)}
            </Typography>
          </Card>
          {!editOn && (
            <Typography
              variant="caption"
              sx={{ color: '#777777', lineHeight: '20px' }}
            >
              {truncate ? truncateText(cardText, truncateValue) : cardText}
            </Typography>
          )}

          {editOn && <div dangerouslySetInnerHTML={{ __html: cardText }} />}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
