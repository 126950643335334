import React, { useState } from 'react'
import {
  Box,
  CardActionArea,
  CardMedia,
  CircularProgress,
  Grid,
  Pagination,
  Stack,
} from '@mui/material'
import { useData } from '../DataContext/DataContext'
import { CardBuidlerV2 } from '../CardBuilder/CardBuilderV2'

const CARDS_PER_PAGE = 6
const START_INDEX = 8

function HomeScndRwFstColm() {
  const { cards } = useData()
  const [currentPage, setCurrentPage] = useState(1)

  const handlePageChange = (event, value) => {
    setCurrentPage(value)
  }

  // const indexOfLastCard = START_INDEX + currentPage * CARDS_PER_PAGE
  const indexOfLastCard = START_INDEX + CARDS_PER_PAGE
  const indexOfFirstCard = indexOfLastCard - CARDS_PER_PAGE
  const currentCards = cards.slice(indexOfFirstCard, indexOfLastCard)

  if (cards.length <= START_INDEX) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          minHeight: '400px',
          marginTop: '200px',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box marginTop={4}>
      <Grid container spacing={2}>
        {currentCards.map((card, index) => (
          <Grid key={index} item md={6} sm={6} xs={12}>
            <CardBuidlerV2
              cardImgUrl={card.imagelink}
              cardHeader={card.headertext}
              cardDate={card.date}
              cardText={card.shortdescription}
              cardBadge={card.category.name}
              truncate={true}
              cardImageWidth="100%"
              truncateValue={100}
              cardHeight={450}
              cardWidth={'100%'}
              elevation={2}
              cardId={card._id}
              slug={card.slug}
              // cardImageHeight={250}
            />
          </Grid>
        ))}
      </Grid>

      <Grid item sx={{ width: '100%', marginBottom: '30px' }}>
        <CardActionArea>
          <CardMedia
            component="img"
            image="https://assets-global.website-files.com/5f0a4c1cde4cddac7d2ad3b4/63984482665829dad1eddd9a_Your%20Startups%20Strategy%20Partner.png"
          />
        </CardActionArea>
      </Grid>

      {/* <Box
        sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
      >
        <Stack spacing={2}>
          <Pagination
            count={Math.ceil((cards.length - START_INDEX) / CARDS_PER_PAGE)}
            page={currentPage}
            onChange={handlePageChange}
            shape="rounded"
            variant="outlined"
            color="primary"
          />
        </Stack>
      </Box> */}
    </Box>
  )
}

export default HomeScndRwFstColm
