import React, { useState } from 'react'
import {
  Alert,
  Backdrop,
  CardMedia,
  CircularProgress,
  Paper,
  TextField,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { SaaSButton } from '../ThemeCust'
import { Login } from '@mui/icons-material'
import LogoutIcon from '@mui/icons-material/Logout'
import SubscribeCardAlike from '../HomeCompCont/SubscribeCard'
import { useData } from '../DataContext/DataContext'

const contanierStyle = {
  textAlign: 'center',
  width: '350px',
  margin: '0 auto',
  marginTop: '100px',
  marginBottom: '100px',
  backgroundColor: '#f3f0f0ac',
}
const inputPropStyle = {
  style: {
    color: 'white',
    fontSize: '14px',
    fontWeight: '500',
  },
}

const inputStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'yellow',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'yellow',
    },
  },
  marginTop: '20px',
}

// const initialValues = {
//   email: '',
//   password: '',
// }

function LogIn() {
  const {
    logged,
    logInHanlder,
    logOutHanlder,
    input,
    setInputs,
    loginFromSubmitHandler,
  } = useData()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const navigate = useNavigate()

  const handleChange = (e) => {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value.trim(),
    }))
  }

  const formSubmitHandlerCall = async (e) => {
    e.preventDefault()
    setLoading(true)
    setError(null)
    try {
      const loginResponse = await loginFromSubmitHandler(
        input.email,
        input.password
      )

      if (loginResponse.data.status === 'success') {
        navigate('/admin-panel')
      } else {
        setError('Login failed: Invalid credentials')
      }
    } catch (error) {
      setError('Login failed: Invalid credentials')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div style={contanierStyle}>
      <Paper elevation={3} sx={{ padding: '20px', backgroundColor: '#242424' }}>
        <SubscribeCardAlike header="DSHG Sonic" formOn={false} />

        <div>
          {error && <Alert severity="error">{error}</Alert>}
          <form onSubmit={formSubmitHandlerCall}>
            {!logged.state ? (
              <>
                <TextField
                  name="email"
                  value={input.email}
                  placeholder="Your email"
                  type="text"
                  variant="outlined"
                  fullWidth
                  sx={inputStyle}
                  InputProps={inputPropStyle}
                  onChange={handleChange}
                />
                <br />
                <TextField
                  name="password"
                  value={input.password}
                  placeholder="password"
                  type="password"
                  variant="outlined"
                  fullWidth
                  sx={inputStyle}
                  InputProps={inputPropStyle}
                  onChange={handleChange}
                />
              </>
            ) : (
              <Alert severity="info">You are Logged In</Alert>
            )}

            {!logged.state ? (
              <SaaSButton
                sx={{ marginTop: '20px' }}
                type="submit"
                onClick={logInHanlder}
              >
                <Login />
                {logged.value}
              </SaaSButton>
            ) : (
              <SaaSButton
                sx={{ marginTop: '20px' }}
                type="submit"
                onClick={logOutHanlder}
              >
                <LogoutIcon />
                {logged.value}
              </SaaSButton>
            )}
          </form>
        </div>
      </Paper>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}

export default LogIn
