import React, { useState, useEffect } from 'react'
import {
  Box,
  Paper,
  Snackbar,
  Alert,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Button,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useData } from '../../../DataContext/DataContext'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Cancel'
import {
  fetchDeleteMetadescription,
  fetchUpdateMetaDescription,
} from '../../../DataContext/DataService'
import { SaaSButton } from '../../../ThemeCust'
import CreateMeta from './CreateMeta'

const Metadescriptions = () => {
  const { metaDescription, isLoading, setMetaDescription } = useData()
  const [rows, setRows] = useState([])
  const [addMeta, setAddMeta] = useState(false)
  const [editRowId, setEditRowId] = useState(null)
  const [editRowData, setEditRowData] = useState({})
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  })
  const [deleteRow, setDeleteRow] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    setRows(
      metaDescription.map((meta, index) => ({
        id: index + 1,
        serverId: meta._id,
        name: meta.name,
        metadescription: meta.metadescription,
      }))
    )
  }, [metaDescription])

  const clickHandler = () => {
    setAddMeta(!addMeta)
  }

  const handleEditClick = (row) => {
    setEditRowId(row.id)
    setEditRowData(row)
  }

  const handleEditChange = (e) => {
    const { name, value } = e.target
    setEditRowData((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleSaveClick = async () => {
    try {
      const { serverId, name, metadescription } = editRowData
      // console.log('serverId', serverId)
      const response = await fetchUpdateMetaDescription(serverId, {
        name,
        metadescription,
      })
      if (response.message === 'Meta description updated') {
        setMetaDescription((prevMetaDescriptions) =>
          prevMetaDescriptions.map((meta) =>
            meta._id === serverId ? { ...meta, name, metadescription } : meta
          )
        )
      }

      setEditRowId(null)
      setSnackbar({
        open: true,
        message: 'Meta description updated successfully',
        severity: 'success',
      })
    } catch (error) {
      console.error('Error updating meta description:', error)
      setSnackbar({
        open: true,
        message: 'Failed to update meta description',
        severity: 'error',
      })
    }
  }

  const handleCancelClick = () => {
    setEditRowId(null)
  }

  const handleDeleteClick = (row) => {
    setDeleteRow(row)
    setOpenDialog(true)
  }

  const handleDialogClose = () => {
    setOpenDialog(false)
  }

  const handleConfirmDelete = async () => {
    setLoading(true)
    try {
      await fetchDeleteMetadescription(deleteRow.serverId)
      setMetaDescription((prevMetaDescriptions) =>
        prevMetaDescriptions.filter((meta) => meta._id !== deleteRow.serverId)
      )
      setSnackbar({
        open: true,
        message: 'Meta description deleted successfully',
        severity: 'success',
      })
    } catch (error) {
      console.error('Error deleting meta description:', error)
      setSnackbar({
        open: true,
        message: 'Failed to delete meta description',
        severity: 'error',
      })
    } finally {
      setLoading(false)
      setOpenDialog(false)
      setDeleteRow(null)
    }
  }

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false })
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: isSmallScreen ? 50 : 60 },
    {
      field: 'name',
      headerName: 'Name',
      width: isSmallScreen ? 100 : 150,
      renderCell: (params) => {
        if (editRowId === params.row.id) {
          return (
            <TextField
              name="name"
              value={editRowData.name}
              onChange={handleEditChange}
            />
          )
        }
        return params.row.name
      },
    },
    {
      field: 'metadescription',
      headerName: 'Metadescription',
      width: isSmallScreen ? 500 : 700,
      renderCell: (params) => {
        if (editRowId === params.row.id) {
          return (
            <TextField
              name="metadescription"
              value={editRowData.metadescription}
              onChange={handleEditChange}
              fullWidth
              multiline
              rows={2}
              autoFocus
              onKeyDown={(e) => e.stopPropagation()} // Prevents unintended DataGrid interactions
            />
          )
        }
        return params.row.metadescription
      },
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: isSmallScreen ? 60 : 90,
      renderCell: (params) => {
        if (editRowId === params.row.id) {
          return (
            <>
              <IconButton onClick={handleSaveClick}>
                <SaveIcon />
              </IconButton>
              <IconButton onClick={handleCancelClick}>
                <CancelIcon />
              </IconButton>
            </>
          )
        }
        return (
          <EditIcon
            onClick={() => handleEditClick(params.row)}
            sx={{ cursor: 'pointer' }}
          />
        )
      },
    },
    {
      field: 'delete',
      headerName: 'Delete',
      width: isSmallScreen ? 60 : 90,
      renderCell: (params) => (
        <DeleteIcon
          onClick={() => handleDeleteClick(params.row)}
          sx={{ cursor: 'pointer' }}
        />
      ),
    },
  ]

  return (
    <Paper
      sx={{
        height: 'auto',
        width: '100%',
        marginTop: 3,
      }}
    >
      <Box
        sx={{
          backgroundColor: '#262320',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: {
            lg: 'flex-end',
            md: 'right',
            sm: 'center',
            xs: 'center',
          },
          alignItems: 'center',
          paddingRight: 5,
        }}
      >
        <SaaSButton
          onClick={clickHandler}
          sx={{
            borderRadius: '10px',
            textTransform: 'none',
            fontWeight: 700,
            padding: '12px 24px',
            marginLeft: 4,
            marginBottom: 2,
          }}
        >
          {addMeta ? 'Show Metadescriptions' : 'Add Metadescriptions'}
        </SaaSButton>
      </Box>
      <Box sx={{ width: '100%' }}>
        {addMeta ? (
          <CreateMeta metaCreated={setAddMeta} />
        ) : (
          <DataGrid
            loading={isLoading}
            rows={rows}
            columns={columns}
            pageSize={isSmallScreen ? 5 : 10} // Adjust the number of rows per page based on screen size
            rowsPerPageOptions={[5, 10, 20]} // Allow different page size options
            autoHeight={isSmallScreen} // Enable auto height for small screens
            disableColumnMenu // Optionally disable the column menu for simplicity
            sx={{
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#f0f0f0',
              },
              '& .MuiDataGrid-cell': {
                alignItems: 'center',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: isSmallScreen ? '90vw' : 'inherit',
              },
            }}
          />
        )}
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this meta description?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel{' '}
          </Button>
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <Button onClick={handleConfirmDelete} color="error" autoFocus>
              Yes, Delete
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Paper>
  )
}

export default Metadescriptions
