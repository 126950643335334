import React from 'react'
import { Grid, Typography } from '@mui/material'
import { SaaSButton } from '../ThemeCust'
import { CardBuilder } from '../CardBuilder/CardBuilder'
import { useData } from '../DataContext/DataContext'
import SubscribeCardAlike from '../HomeCompCont/SubscribeCard'
import { useNavigate } from 'react-router-dom'

function AmazingTeam({ addAuthor, setAddAuthor }) {
  const { authors } = useData()
  const navigate = useNavigate()

  const clickHandler = () => {
    // navigate('/author-create', {
    //   state: {
    //     // padding: '100px 0px 50px 200px',
    //     // backgroundColor: '#262320',
    //   },
    // })
    setAddAuthor(!addAuthor)
  }

  return (
    <Grid container spacing={4} marginTop={4} paddingBottom={10}>
      <Grid item xs={12} sm={1} md={1} />
      <Grid
        item
        xs={12}
        sm={10}
        md={3}
        sx={{ width: '100%', textAlign: 'center' }}
      >
        <SubscribeCardAlike formOn={false} header={'Our Amazing Team'} />

        <Typography sx={{ color: '#f3f0f0ac', marginTop: -5 }}>
          Writing result-oriented ad copy is difficult, as it must appeal to,
          entice, and convince consumers to take action
        </Typography>

        <SaaSButton
          onClick={clickHandler}
          sx={{
            marginTop: '20px',
            borderRadius: '10px',
            textTransform: 'none',
            fontWeight: 700,
            padding: '12px 24px',
          }}
        >
          Become An Author
        </SaaSButton>
      </Grid>

      <Grid item xs={12} sm={12} md={7}>
        <Grid container spacing={4}>
          {authors
            .filter((author) => author.pending === false)
            .map((author, index) => (
              <Grid item xs={12} sm={6} md={6} key={index}>
                <CardBuilder
                  authorName={author.name}
                  truncate={false}
                  navigationOn={true}
                  cardContentDisplay={'#f3f0f0ac'}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>

      <Grid item xs={12} sm={1} md={1} />
    </Grid>
  )
}

export default AmazingTeam
