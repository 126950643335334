import React, { useState, useEffect } from 'react'
import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  CircularProgress,
  Typography,
} from '@mui/material'
import TwitterIcon from '@mui/icons-material/Twitter'
import FacebookIcon from '@mui/icons-material/Facebook'
import PinterestIcon from '@mui/icons-material/Pinterest'
import MailIcon from '@mui/icons-material/Mail'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import TodayIcon from '@mui/icons-material/Today'
import ScheduleIcon from '@mui/icons-material/Schedule'
import { useParams, useNavigate } from 'react-router-dom'
import { useData } from '../DataContext/DataContext'
import { SaaSButton } from '../ThemeCust'
import FeaturePostCreate from '../CardBuilder/FeaturePostCreate'
import { truncateText } from '../CardBuilder/TextTruncate'
import SubscribeCardAlike from '../HomeCompCont/SubscribeCard'
import HtmlParser from './HtmlParser'
import { MetadescriptionSetterForPage } from '../../utils/MetaDescriptionSetter'

const textStyles = {
  color: '#ffd400',
  textShadow: '2px 1px 1px rgba(0, 0, 0, 0.7)',
  fontWeight: 700,
  mr: 0.5,
}

const iconButtonStyles = {
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  color: 'white',
  mb: 2,
  '&:hover': {
    color: '#ffd400',
    backgroundColor: 'grey',
  },
}

function PageBuilder() {
  // const { id } = useParams()
  let { slug } = useParams()

  console.log('formattedHeaderText', slug)
  const navigate = useNavigate()
  const { cards } = useData()
  const [loading, setLoading] = useState(true)
  const [card, setCard] = useState(null)
  const [imageLoading, setImageLoading] = useState(true)
  useEffect(() => {
    const fetchCard = async () => {
      setLoading(true)
      // const fetchedCard = cards.find((card) => card._id === id)
      const fetchedCard = cards.find((card) => card.slug === slug)
      console.log('fetchCard', fetchedCard)
      if (fetchedCard) {
        setCard(fetchedCard)
      }
      setLoading(false)
    }

    fetchCard()
  }, [cards, slug])

  const featured =
    cards.length >= 1 &&
    cards.filter((temp) => temp.author.name === (card?.author?.name || ''))
  const featuredPost = featured.length >= 2 ? featured.slice(0, 4) : []

  // if (loading) {
  //   return (
  //     <Box
  //       sx={{
  //         display: 'flex',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //         height: '200vh',
  //         width: '100%',
  //       }}
  //     >
  //       <Box
  //         sx={{
  //           display: 'flex',
  //           justifyContent: 'center',
  //           alignItems: 'center',
  //           width: '80px',
  //           height: '80px',
  //           mt: '80px',
  //         }}
  //       >
  //         <CircularProgress />
  //       </Box>
  //     </Box>
  //   )
  // }

  if (!card) {
    return <Typography variant="h6">Card not found</Typography>
  }

  const handleClick = (authorName) => {
    console.log('Navigating to author:', authorName)
    navigate(`/author-content/${encodeURIComponent(authorName)}`)
  }

  const clickToCategory = (categoryName) => {
    console.log('Navigating to category: ', categoryName)
    navigate(`/category/${categoryName}`)
  }

  return (
    <>
      {card.headertext && (
        <MetadescriptionSetterForPage metaDescription={card.slug} />
      )}

      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '80px',
              height: '80px',
              mt: '80px',
            }}
          >
            <CircularProgress />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: 'white',
            height: 'auto',
            paddingBottom: 10,
            paddingTop: 7,
          }}
        >
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: { xs: '350px', md: '500px' },
              backgroundColor: '#f0f0f0', // Set a placeholder background color
              color: 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              // padding: 2,
            }}
          >
            {imageLoading && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  zIndex: 1, // Ensure the spinner is above the image
                }}
              >
                <CircularProgress />
              </Box>
            )}

            <img
              src={card.imagelink}
              alt={card.headertext}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'fill',
                display: imageLoading ? 'none' : 'block',
              }}
              onLoad={() => setImageLoading(false)}
            />

            <Box
              sx={{
                position: 'absolute',
                bottom: 50,
                left: { xs: 20, md: 250 },
              }}
            >
              <SaaSButton onClick={() => clickToCategory(card.category.name)}>
                {card.category.name}
              </SaaSButton>
              <Typography
                variant="h2"
                sx={{
                  marginTop: '10px',
                  fontSize: { lg: '40px', md: '24px', xs: '20px' },
                  fontWeight: '600',
                  letterSpacing: '-0.06em',
                  transition: 'color 0.8s ease-In-Out',
                  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
                  color: '#ffd400',
                }}
              >
                {card.headertext}
              </Typography>
              <Box
                onClick={() => handleClick(card.author.name)}
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                mt={2}
                sx={{ cursor: 'pointer' }}
              >
                <PermIdentityIcon
                  sx={{ ...textStyles, fontSize: 20, mb: 0.5 }}
                />
                <Typography
                  sx={{ ...textStyles, textDecoration: 'underline' }}
                  variant="caption"
                >
                  {card.author.name}
                </Typography>
                <Box display="flex" alignItems="center" ml={2}>
                  <TodayIcon sx={{ ...textStyles, fontSize: 20, mb: 0.5 }} />
                  <Typography sx={{ ...textStyles }} variant="caption">
                    {new Date(card.date).toDateString()}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" ml={2}>
                  <ScheduleIcon sx={{ ...textStyles, fontSize: 20, mb: 0.5 }} />
                  <Typography sx={{ ...textStyles }} variant="caption">
                    5 min read
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* Body content */}
          <Grid container spacing={2} mt={2} padding={5}>
            {/* Left column: Social media icons */}
            <Grid item xs={12} md={2}>
              <Box
                display={{ xs: 'none', md: 'flex' }}
                flexDirection="column"
                alignItems="center"
              >
                <IconButton
                  sx={{ ...iconButtonStyles, backgroundColor: '#1DA1F2' }}
                >
                  <TwitterIcon />
                </IconButton>
                <IconButton
                  sx={{ ...iconButtonStyles, backgroundColor: '#3b5998' }}
                >
                  <FacebookIcon />
                </IconButton>
                <IconButton
                  sx={{ ...iconButtonStyles, backgroundColor: '#E60023' }}
                >
                  <PinterestIcon />
                </IconButton>
                <IconButton
                  sx={{ ...iconButtonStyles, backgroundColor: 'black' }}
                >
                  <MailIcon />
                </IconButton>
              </Box>
            </Grid>

            {/* Middle column: Body content */}
            <Grid
              item
              xs={12}
              md={7}
              sx={{
                padding: {
                  xs: '10px 10px 20px 10px',
                  md: '10px 20px 20px 10px',
                },
              }}
            >
              {/* Render the HTML content using HtmlParser */}
              <Box>
                <HtmlParser body={card.body} />
                {/* {card.body} */}
              </Box>

              <Box
                display={{ xs: 'flex', md: 'none' }}
                justifyContent="center"
                mt={2}
              >
                <IconButton
                  sx={{ ...iconButtonStyles, backgroundColor: '#1DA1F2' }}
                >
                  <TwitterIcon />
                </IconButton>
                <IconButton
                  sx={{ ...iconButtonStyles, backgroundColor: '#3b5998' }}
                >
                  <FacebookIcon />
                </IconButton>
                <IconButton
                  sx={{ ...iconButtonStyles, backgroundColor: '#E60023' }}
                >
                  <PinterestIcon />
                </IconButton>
                <IconButton
                  sx={{ ...iconButtonStyles, backgroundColor: 'black' }}
                >
                  <MailIcon />
                </IconButton>
              </Box>
            </Grid>

            {/* Right column: Featured posts */}
            <Grid
              item
              xs={12}
              md={3}
              backgroundColor={'#262320'}
              height={{ md: '100%', lg: '700px' }}
              width={'100%'}
              // paddingLeft={5}
            >
              <Box sx={{ width: '100%', height: 'auto' }}>
                <SubscribeCardAlike formOn={false} header={'Featured Posts'} />
              </Box>
              <Box width="100%" sx={{ marginTop: -12, paddingRight: 2 }}>
                <LinearProgress
                  variant="determinate"
                  value={30}
                  sx={{
                    backgroundColor: 'grey',
                    height: '4px',
                    borderRadius: '5px',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#000',
                      borderRadius: '5px',
                    },
                    mt: '80px', // margin top for linear progress
                  }}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                mt={2}
                marginLeft={-2}
                padding={2}
              >
                {featuredPost.length > 0 &&
                  featuredPost.map((temp) => (
                    <FeaturePostCreate
                      key={temp._id}
                      headerText={temp.headertext}
                      bodyText={truncateText(temp.body, 50)}
                      imageUrl={temp.imagelink}
                      cardId={temp._id}
                      slug={temp.slug}
                    />
                  ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  )
}

export default PageBuilder
