import { useState } from 'react'
import PreviewIcon from '@mui/icons-material/Preview'
import { Box, Modal } from '@mui/material'
import { CardBuidlerV2 } from '../../../CardBuilder/CardBuilderV2'
import { CardBuilder } from '../../../CardBuilder/CardBuilder'

export function Preview({
  previewValue,
  card = true,
  author = false,
  category = false,
  meta = false,
}) {
  const [modelOpen, setModalOpen] = useState(false)

  // console.log('Preview value on view,previewValue', previewValue)
  const closeModal = () => {
    setModalOpen(!modelOpen)
  }
  return (
    <>
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <PreviewIcon
          onClick={() => setModalOpen(true)}
          sx={{ cursor: 'pointer' }}
        />
      </Box>
      <Modal
        open={modelOpen}
        sx={{
          paddingLeft: '400px',
          paddingRight: '400px',
          paddingTop: '100px',
        }}
        onClose={closeModal}
      >
        {card ? (
          <CardBuidlerV2
            cardHeader={previewValue.headerText}
            cardImgUrl={previewValue.image}
            cardBadge={previewValue.category}
            cardDate={previewValue.date}
            cardText={previewValue.body}
            cardId={previewValue.serverId}
            truncate={false}
            animation={false}
            editOn={true}
            slug={previewValue.slug}
          />
        ) : author ? (
          <Box width={420} height={525} backgroundColor={'#262320'}>
            <CardBuilder authorName={previewValue.name} navigationOn={true} />
          </Box>
        ) : (
          <CardBuilder />
        )}
      </Modal>
    </>
  )
}

export default Preview
