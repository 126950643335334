import React, { useState, useMemo } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useData } from '../DataContext/DataContext'
import { CardBuidlerV2 } from '../CardBuilder/CardBuilderV2'
import {
  Grid,
  Box,
  Card,
  CardMedia,
  Typography,
  Paper,
  Pagination,
} from '@mui/material'
import SubscribeCardAlike from '../HomeCompCont/SubscribeCard'
import { CategoryGrid } from './CategoryColor'
import { MetadescriptionSetterForPage } from '../../utils/MetaDescriptionSetter'

const ITEMS_PER_PAGE = 4

function CategoryWiseArticle() {
  const { categoryName } = useParams()
  const { cards, categories } = useData()
  const navigate = useNavigate()
  const [page, setPage] = useState(1)

  // Use useMemo to memoize the filtered cards
  const cardsOfCategory = useMemo(() => {
    return cards.filter((card) => card.category.name === categoryName)
  }, [cards, categoryName])

  const handlePageChange = (event, value) => {
    setPage(value)
  }

  const paginatedCards = useMemo(() => {
    const startIndex = (page - 1) * ITEMS_PER_PAGE
    return cardsOfCategory.slice(startIndex, startIndex + ITEMS_PER_PAGE)
  }, [page, cardsOfCategory])

  const clickToCategory = (categoryName) => {
    navigate(`/category/${categoryName}`)
  }

  return (
    <>
      <MetadescriptionSetterForPage
        metaDescription={`Listed blogs by ${categoryName}`}
      />
      <CategoryGrid />
      {/* Ensure colors are set for categories */}
      <Paper sx={{ backgroundColor: 'white' }}>
        <Card sx={{ width: '100%', position: 'relative' }}>
          <CardMedia
            component="img"
            image="https://assets-global.website-files.com/5f0a4c1cde4cddac7d2ad3b4/5f0d1842cf63ccc11bf0aeb4_Advertise_With_Us-HeaderImage.jpg"
            sx={{
              height: { xs: '200px', sm: '300px', md: '350px' },
              marginTop: '30px',
            }}
          />
          <Typography
            variant="h2"
            fontWeight={700}
            sx={{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)', // Center vertically
              color: '#ffd400',
              textAlign: 'left',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
              fontSize: { xs: '24px', sm: '32px', md: '40px' },
              padding: '0 10px', // Ensure text doesn't overflow on small screens
            }}
          >
            {categoryName} Category
          </Typography>
        </Card>

        <Grid container spacing={4} sx={{ padding: { md: 10, sm: 5, xs: 2 } }}>
          {/* Display paginated cards */}
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              {paginatedCards.map((card, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <CardBuidlerV2
                    cardId={card._id}
                    cardImgUrl={card.imagelink}
                    cardHeader={card.headertext}
                    cardText={card.shortdescription}
                    cardBadge={card.category.name}
                    truncateValue={150}
                    truncate={true}
                    cardDate={card.date}
                    animation={true}
                    slug={card.slug}
                    cardHeight="400"
                  />
                </Grid>
              ))}
            </Grid>
            <Pagination
              count={Math.ceil(cardsOfCategory.length / ITEMS_PER_PAGE)}
              page={page}
              onChange={handlePageChange}
              sx={{
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'center',
                color: 'black',
                '& .MuiPaginationItem-root': {
                  color: 'black',
                },
                '& .MuiPaginationItem-root.Mui-selected': {
                  backgroundColor: 'white',
                  color: 'black',
                },
                '& .MuiPaginationItem-root.MuiPaginationItem-ellipsis': {
                  color: 'black',
                },
              }}
            />
          </Grid>

          {/* categories */}
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: 'flex',
              justifyContent: {
                md: 'left',
                sm: 'center',
                xs: 'center',
              },
              // alignItems: 'center',
              // backgroundColor: '#262320',
            }}
          >
            <Box
              sx={{
                marginTop: -5,
                textAlign: 'center',
              }}
            >
              <Box
                sx={{
                  padding: { md: 7, xs: 12, sm: 20 },
                  marginTop: { md: -6, xs: -10, sm: -15 },
                }}
              >
                <SubscribeCardAlike
                  header={'Categories'}
                  formOn={false}
                  backgroundColor="white"
                />
              </Box>
              <Box sx={{ marginTop: { md: -10, xs: -15, sm: -20 } }}>
                {categories.map((category, index) => (
                  <Paper
                    onClick={() => clickToCategory(category.name)}
                    key={index}
                    sx={{
                      backgroundColor: category.color,
                      padding: '8px',
                      marginBottom: '8px',
                      color: '#fff',
                      cursor: 'pointer',
                      transition: 'all 0.6s ease-out',
                      '&:hover': {
                        paddingLeft: '30px',
                        backgroundColor: '#333',
                        transition: 'all 0.5s ease-out',
                      },
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)',
                        color: 'Black',
                        transition: 'color 0.3s ease-in-out',
                        '&:hover': {
                          color: 'white',
                          transition: 'color 0.3s ease-in-out',
                        },
                      }}
                    >
                      {category.name}
                    </Typography>
                  </Paper>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default CategoryWiseArticle
