import React from 'react'
import {
  Box,
  Grid,
  ListItemText,
  IconButton,
  Paper,
  Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ImgLogoComp } from '../ThemeCust'
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import YouTubeIcon from '@mui/icons-material/YouTube'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import FooterLink from './FooterLink'
import StyledLink from '../../utils/StyledLink'

const importantLinkArry = [
  'Submit a Press Release',
  'Editorial Policy',
  'Advertise',
  'Careers',
  'About us',
]

const iconStyle = {
  backgroundColor: '#777777',
  margin: '4px', // Adjusted margin to reduce gaps
  '&:hover': {
    backgroundColor: 'white',
  },
}

function CustomPaper({ children }) {
  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: 'transparent',
        padding: '10px', // Consistent padding
        margin: 0, // Remove margin to avoid extra gaps
      }}
    >
      {children}
    </Paper>
  )
}

function Footer() {
  const navigate = useNavigate()
  const clickHandler = (link) => {
    if (link === 'About us') {
      navigate('/about-us')
    }
  }

  return (
    <Paper
      sx={{
        backgroundColor: '#242424',
        padding: '20px',
        borderTop: '1px solid',
        borderColor: '#ffd400',
      }}
    >
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={12} md={3}>
          <CustomPaper>
            <ImgLogoComp />
            <Typography
              sx={{
                color: 'white',
                fontSize: { xs: '12px', sm: '14px' },
                lineHeight: '1.5',
              }}
            >
              Your #1 source for
              <StyledLink
                to="/"
                sx={{
                  color: '#ffd420',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                    textDecorationColor: '#ffd420',
                  },
                }}
              >
                {' '}
                SaaS news
              </StyledLink>
              , trends & happenings. Discuss new products, tool launches,
              community updates, funding updates and anything related to the
              space.
              <br />
              <br />
              We are looking to help the bootstrapped SaaS founders worldwide
              get a platform to showcase their products and learn from others
              and become successful SaaS Operators and founders.
            </Typography>
          </CustomPaper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ color: '#ffd400' }}>
            <FooterLink />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            display: 'flex',
            justifyContent: { xs: 'flex-start', md: 'center' },
          }}
        >
          <CustomPaper>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ color: '#ffd400', marginBottom: '10px' }} // Reduced marginBottom
            >
              Important Links
            </Typography>
            {importantLinkArry.map((link, index) => (
              <ListItemText
                onClick={() => clickHandler(link)}
                key={index}
                primary={link}
                sx={{
                  color: '#ffffff',
                  textAlign: 'left',
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              />
            ))}
          </CustomPaper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper
            elevation={0}
            sx={{
              backgroundColor: 'transparent',
              color: '#ffd400',
              textAlign: 'center',
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{ color: '#ffd400', marginBottom: '10px' }} // Reduced marginBottom
            >
              Connect with us
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
              }}
            >
              <IconButton aria-label="Facebook" sx={iconStyle}>
                <FacebookIcon />
              </IconButton>
              <IconButton aria-label="Twitter" sx={iconStyle}>
                <TwitterIcon />
              </IconButton>
              <IconButton aria-label="Instagram" sx={iconStyle}>
                <InstagramIcon />
              </IconButton>
              <IconButton aria-label="LinkedIn" sx={iconStyle}>
                <LinkedInIcon />
              </IconButton>
              <IconButton aria-label="YouTube" sx={iconStyle}>
                <YouTubeIcon />
              </IconButton>
              <IconButton aria-label="Newsletter" sx={iconStyle}>
                <MailOutlineIcon />
              </IconButton>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Footer
