import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
} from '@mui/material'
import { useState, useEffect } from 'react'
import { useData } from '../../../DataContext/DataContext'
import { SaaSButton } from '../../../ThemeCust'
import axios from 'axios'
import { Editor } from '@tinymce/tinymce-react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import SubscribeCardAlike from '../../../HomeCompCont/SubscribeCard'
import { handleEditorImageUpload } from '../../../../utils/imageUpload'

const inputStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'black',
      width: '100%',
    },
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
      width: '100%',
    },
  },
  textAlign: 'left',
}
const inputContainerStyle = {
  ...inputStyle,
  marginBottom: '20px',
  '& .MuiInputLabel-root': {
    color: 'black',
  },
  '& .MuiSelect-icon': {
    color: 'black',
  },
  width: '100%',
}

function ArticleEdit() {
  const {
    cardEditValue,
    setCardEditState,
    setCardEditValue,
    setSelectedTab,
    setCards,
    categories,
    authors,
  } = useData()
  // const editorRef = useRef(null)

  // const log = () => {
  //   if (editorRef.current) {
  //     // console.log(editorRef.current.getContent())
  //   }
  // }

  // console.log('cardEditValue ', cardEditValue)

  const [input, setInput] = useState({
    author: cardEditValue.author._id,
    category: cardEditValue.category._id,
    body: cardEditValue.body,
    headertext: cardEditValue.headertext,
    imagelink: cardEditValue.imagelink,
    date: cardEditValue.date,
  })
  const inputId = cardEditValue._id
  const [imagePreview, setImagePreview] = useState(null)
  const [snackbarKey, setSnackbarKey] = useState(0)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [error, setErr] = useState(false)
  const [loading, setLoading] = useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [bodyContent, setBodyContent] = useState(input.body || '')

  useEffect(() => {
    setInput({
      author: cardEditValue.author._id,
      category: cardEditValue.category._id,
      body: cardEditValue.body,
      headertext: cardEditValue.headertext,
      imagelink: cardEditValue.imagelink,
      shortdescription: cardEditValue.shortdescription,
      metadescription: cardEditValue.metadescription,
      date: cardEditValue.date,
    })
    setBodyContent(cardEditValue.body)
  }, [cardEditValue])

  const handleChange = (e, newValue) => {
    if (e && e.target) {
      const { name, value, files } = e.target

      if (name === 'imagelink' && files.length > 0) {
        const selectedFile = files[0]
        const sanitizedFileName = selectedFile.name.replace(/\s+/g, '_')
        if (!selectedFile.type.startsWith('image/')) {
          alert('Please select an image file.')
          return
        }
        const sanitizedFile = new File([selectedFile], sanitizedFileName, {
          type: selectedFile.type,
        })

        setImagePreview(URL.createObjectURL(selectedFile))

        setInput((prevState) => ({
          ...prevState,
          [name]: sanitizedFile,
        }))
      } else {
        setInput((prevState) => ({
          ...prevState,
          [name]: value,
        }))
      }
    } else if (newValue) {
      setInput((prevState) => ({
        ...prevState,
        date: newValue,
      }))
    }
  }

  const handleEditorChange = (content) => {
    setBodyContent(content)
    setInput((prevState) => ({
      ...prevState,
      body: content,
    }))
  }

  const cardEditFormSubmitHandler = async (e) => {
    e.preventDefault()
    setLoading(true) // Show the modal and progress bar
    try {
      const formData = new FormData()
      Object.entries(input).forEach(([key, value]) => {
        formData.append(key, value)
      })
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/cards/${inputId}`,
        formData
      )

      if (response.data.message === 'Card updated') {
        setSnackbarOpen(true)
        setSnackbarSeverity('success')
        setSnackbarMessage('Article edited successfully!')
        const cardsData = response && response.data.data
        const reversedCardsData = cardsData.cards.reverse()
        setCards(reversedCardsData)
        setCardEditValue(null)
        setCardEditState(false)
        setSelectedTab('View Articles')
        setSnackbarKey((prevKey) => prevKey + 1)
        setLoading(false)
      }
    } catch (error) {
      console.log('Error Updation', error)
      setLoading(false) // Hide the modal and progress bar in case of error
      throw error
    }
  }

  const cancelButtonClick = () => {
    setCardEditState(false)
    setCardEditValue(null)
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
    setCardEditState(false)
    setSelectedTab('View Articles')
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'left',
        flexDirection: 'column',
        alignItems: 'left',
        backgroundColor: '#262320',
        padding: '40px',
        // width: '90%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          // flexDirection: 'column',
          justifyContent: 'left',
          alignItems: 'left',
          marginLeft: -4,
          marginBottom: -3,
          marginTop: -4,
        }}
      >
        <SubscribeCardAlike
          formOn={false}
          header={'Edit the article details'}
        />
      </Box>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: 'white',
          padding: '30px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <form onSubmit={cardEditFormSubmitHandler} style={{ width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
              width: '100%',
              alignItems: 'center',
              marginBottom: '20px',
            }}
          >
            {/* display image */}
            <img
              src={cardEditValue.imagelink}
              alt="Preview"
              style={{ height: 200, marginRight: 10, width: '40%' }}
            />
            {/* upload image */}
            <TextField
              name="imagelink"
              id="image-upload"
              accept="image/*"
              type="file"
              variant="outlined"
              style={{ display: 'none' }}
              onChange={handleChange}
            />
            <label htmlFor="image-upload">
              <SaaSButton component="span">Upload Image</SaaSButton>
            </label>
            {imagePreview && (
              <img
                src={imagePreview}
                alt="Preview"
                style={{ height: 100, marginLeft: 10 }}
              />
            )}
          </Box>

          <FormControl variant="outlined" sx={inputContainerStyle}>
            <InputLabel shrink>Category</InputLabel>
            <Select
              name="category"
              value={input.category}
              onChange={handleChange}
              label="Category"
            >
              {categories.length > 0 ? (
                categories.map((category) => (
                  <MenuItem key={category._id} value={category._id}>
                    {category.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>Loading categories...</MenuItem>
              )}
            </Select>
          </FormControl>

          <TextField
            name="headertext"
            label="Header Text"
            value={input.headertext}
            type="text"
            variant="outlined"
            sx={inputContainerStyle}
            onChange={handleChange}
          />

          <FormControl variant="outlined" sx={inputContainerStyle}>
            <InputLabel shrink={true}>Author</InputLabel>
            <Select
              name="author"
              value={input.author}
              onChange={handleChange}
              label="Author"
            >
              {authors.length > 0 ? (
                authors.map((author) => (
                  <MenuItem key={author._id} value={author._id}>
                    {author.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>Loading authors...</MenuItem>
              )}
            </Select>
          </FormControl>

          <TextField
            name="shortdescription"
            label="Shortdescription"
            value={input.shortdescription}
            type="text"
            variant="outlined"
            sx={inputContainerStyle}
            onChange={handleChange}
          />
          <TextField
            name="metadescription"
            label="Metadescription"
            value={input.metadescription}
            type="text"
            variant="outlined"
            sx={inputContainerStyle}
            onChange={handleChange}
          />

          <Box sx={{ margin: '5px 0 20px 0' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={input.date ? dayjs(input.date) : null}
                onChange={(newDate) => handleChange(null, newDate)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    sx={inputContainerStyle}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>

          <Editor
            apiKey="0db2zg1hl6crmc1q0rxtjcqqe5pgkjex5r5yx7q8v07e7nj2"
            value={bodyContent}
            init={{
              height: 500,
              selector: 'textarea',
              plugins:
                'advlist autolink lists  link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code wordcount accordion help',
              toolbar:
                'undo redo |  styles  | formatselect | bold italic backcolor | code | link |image |alignleft aligncenter alignright alignjustify |numlist bullist | print ',

              menu: {
                favs: {
                  title: 'My Favorites',
                  items: 'code visualaid | searchreplace | emoticons',
                },
              },
              menubar:
                'favs file edit view insert  lists format tools table help numlist bullist',
              images_upload_handler: handleEditorImageUpload,
            }}
            onEditorChange={(content) => handleEditorChange(content)}
          />

          <Snackbar
            key={snackbarKey}
            open={snackbarOpen || error}
            autoHideDuration={2000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            {error ? (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
              </Alert>
            ) : (
              <Alert severity="success">
                <AlertTitle>Card Edited</AlertTitle>
                Card updated
              </Alert>
            )}
          </Snackbar>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ width: '45%', marginRight: '10px' }}
            >
              Update
            </Button>
            <Button
              onClick={cancelButtonClick}
              type="reset"
              variant="contained"
              color="primary"
              sx={{ width: '45%', marginLeft: '10px' }}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Paper>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default ArticleEdit
