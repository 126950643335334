import { useState } from 'react'
import { CircularProgress, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { useData } from '../../../DataContext/DataContext'
import { fetchGetMetaDescription } from '../../../DataContext/DataService'

function EditButton({ id, name }) {
  const { cardGet, authorGet } = useData()
  const [loading, setLoading] = useState(false)

  const handleEdit = async () => {
    if (id !== 'null') {
      setLoading(true)
      try {
        name === 'card' && (await cardGet(id))
        name === 'meta' && (await fetchGetMetaDescription(id))
        name === 'author' && (await authorGet(id))
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <EditIcon
      onClick={handleEdit}
      disabled={loading}
      sx={{ cursor: 'pointer' }}
    >
      {loading ? <CircularProgress size={24} /> : <EditIcon />}
    </EditIcon>
  )
}

export default EditButton
