import React, { useState } from 'react'
import { useData } from '../../../DataContext/DataContext'
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'

const inputStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'black',
    },
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
  },
  textAlign: 'left',
}

function AuthorDelete({ id, onAuthorSelect }) {
  const { authors, cards } = useData()
  const [input, setInput] = useState({ author: '' })

  const handleChange = (event) => {
    const selectedAuthorId = event.target.value
    setInput({ author: selectedAuthorId })
    onAuthorSelect(selectedAuthorId)
    console.log('selected author', selectedAuthorId)
  }

  const restOfAuthor = authors.filter((author) => author._id !== id)
  //   console.log('restOfAuthor', restOfAuthor)

  const authorCardsStageForTransfer = cards.filter(
    (card) => card.author._id === id
  )
  console.log('authorCardsStageForTransfer', authorCardsStageForTransfer)

  return (
    <Box sx={{ padding: 2 }}>
      <FormControl
        variant="outlined"
        sx={{
          ...inputStyle,

          '& .MuiInputLabel-root': {
            color: 'black',
          },
          '& .MuiSelect-icon': {
            color: 'black',
          },
          width: '100%',
        }}
      >
        <InputLabel sx={{ color: 'black' }}>Author</InputLabel>
        <Select
          name="author"
          value={input.author}
          onChange={handleChange}
          label="Author"
          sx={{
            '& .MuiSelect-select': {
              color: 'black',
            },
            '& .MuiMenuItem-root': {
              color: 'black',
            },
          }}
          inputProps={{
            style: { color: 'black' },
          }}
        >
          {restOfAuthor.map((author) => (
            <MenuItem key={author._id} value={author._id}>
              {author.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default AuthorDelete
