import React from 'react'
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { useData } from '../DataContext/DataContext'
import { useNavigate } from 'react-router-dom'

function FooterLink() {
  const navigate = useNavigate()
  const { categories } = useData()
  const catgArry = categories.map((category) => category.name)

  const clickHandler = (text) => {
    console.log('navigating to: ', text)
    if (text === 'Home') {
      navigate('/')
    } else {
      navigate(`/category/${text}`)
    }
  }

  return (
    <Box sx={{ backgroundColor: 'transparent' }}>
      <Typography variant="h6" gutterBottom>
        Navigation
      </Typography>
      <List>
        {['Home', ...catgArry].map((text, index) => (
          <Box key={index}>
            <Divider sx={{ backgroundColor: 'white' }} />
            <ListItem
              onClick={() => clickHandler(text)}
              sx={{
                alignItems: 'center',
                paddingLeft: '2px',
                paddingRight: '2px',
                transition: 'background-color 0.5s, padding-right 0.5s',
                '&:hover': {
                  paddingRight: '5px',
                  backgroundColor: '#333',
                },
              }}
              button
            >
              <ListItemText
                primary={text}
                sx={{
                  color: 'white',
                  transition: 'padding-left 0.5s, background-color 0.5s',
                  '&:hover': {
                    paddingLeft: '30px',
                    backgroundColor: '#333',
                  },
                }}
              />
              <NavigateNextIcon sx={{ color: '#ffd400' }} />
            </ListItem>
          </Box>
        ))}
        <Divider sx={{ backgroundColor: 'white' }} />
      </List>
    </Box>
  )
}

export default FooterLink
