import React, { useEffect, useState } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import DeleteButton from '../TableUtils/DeleteButton'
import PreviewIcon from '@mui/icons-material/Preview'
import SubscribeCard from '../../../HomeCompCont/SubscribeForm'
import { Box, Card, CardContent, Modal, Paper, Typography } from '@mui/material'
import { useData } from '../../../DataContext/DataContext'

const typoStyle = {
  color: '#ffd400',
  fontSize: '16px',
  lineHeight: '26px',
  marginLeft: '5px',
  fontWeight: '700',
}
function PreviewButton({ row }) {
  const [previewOpen, setPreviewOpen] = useState(false)

  const handlePreviewOpen = () => {
    setPreviewOpen(true)
  }

  const handlePreviewClose = () => {
    setPreviewOpen(false)
  }

  return (
    <>
      <PreviewIcon onClick={handlePreviewOpen} sx={{ cursor: 'pointer' }} />
      {previewOpen && (
        <Modal
          open={previewOpen}
          sx={{
            paddingLeft: '360px',
            paddingRight: '360px',
            paddingTop: '100px',
          }}
          onClose={handlePreviewClose}
        >
          <Card>
            <CardContent
              sx={{
                marginTop: -1.5,
                marginBottom: -2.5,
                marginLeft: -1.5,
                marginRight: -1.5,
              }}
            >
              <SubscribeCard header={row.subject} />
              <Box
                sx={{
                  backgroundColor: '#262320',
                  padding: 1,
                }}
              >
                <Typography sx={typoStyle}>Name: {row.name}</Typography>
                <Typography sx={typoStyle}>Email: {row.email}</Typography>
                <Typography sx={typoStyle}>Body: {row.message}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Modal>
      )}
    </>
  )
}

function EnqTable() {
  const { enquiry, isLoading } = useData()
  const [rows, setRows] = useState([])

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 20 },
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
    },
    {
      field: 'subject',
      headerName: 'Subject',
      width: 150,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
    },
    {
      field: 'message',
      headerName: 'Message',
      width: 300,
    },
    {
      field: 'preview',
      headerName: 'Preview',
      width: 120,
      renderCell: (params) => <PreviewButton row={params.row} />,
    },
    {
      field: 'delete',
      headerName: 'Delete',
      width: 120,
      renderCell: (params) => (
        <DeleteButton id={params.row.serverId} name={'enquiry'} />
      ),
    },
  ]

  useEffect(() => {
    setRows(
      enquiry.map((enq, index) => ({
        id: index + 1,
        serverId: enq._id,
        name: enq.name,
        subject: enq.subject,
        email: enq.email,
        message: enq.message,
      }))
    )
  }, [enquiry])
  return (
    <Paper sx={{ width: 'auto' }}>
      <DataGrid
        loading={isLoading}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 6,
            },
          },
        }}
        pageSizeOptions={[6]}
      />
    </Paper>
  )
}

export default EnqTable
