import React from 'react'
import {
  Grid,
  List,
  ListItemText,
  Box,
  Skeleton,
  Typography,
} from '@mui/material'
import { useData } from '../DataContext/DataContext'
import { useNavigate } from 'react-router-dom'
import { fetchCardClick } from '../DataContext/DataService'

function HomeFrstRowScndCol() {
  const navigate = useNavigate()
  const { cards } = useData()

  const clickHandler = async (id, slug) => {
    await fetchCardClick(id)
    navigate(`/blog/${slug}`)
  }

  return (
    <Grid
      container
      sx={{
        justifyContent: 'center',
        display: 'inline',
        alignItems: 'center',
        flexGrow: 1,
      }}
    >
      <Grid item xs={12}>
        <Box
          sx={{
            color: '#050505',
            backgroundColor: '#ffd400',
            cursor: 'default',
            display: 'flex',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px 0', // Optional: add padding for better spacing
          }}
        >
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 700,
              color: '#474444',
              textTransform: 'uppercase',
              // letterSpacing: '0.1em', // Adjusts the spacing between all letters
              wordSpacing: '0.3em', // Alternatively, increases space between words only
            }}
          >
            Recent <></>Posts
          </Typography>
        </Box>

        <List
          sx={{
            padding: '14px 20px',
            borderTop: 'none',
            borderLeft: '1px solid #ffd400',
            borderRight: '1px solid #ffd400',
            borderBottom: '1px solid #ffd400',
          }}
        >
          {cards && cards.length > 0 ? (
            cards.slice(3, 8).map((item, index) => (
              <ListItemText
                onClick={() => clickHandler(item._id, item.slug)}
                key={index}
                sx={{
                  alignContent: 'center',
                  margin: '0 0 14px',
                  padding: '10px 0 0',
                  textShadow: '0 1 2px rgba(0,0,0,0.5)',
                  fontSize: { lg: '25px', md: '12px', sm: '10px' },
                  color: '#474444',
                  cursor: 'pointer',
                  letterSpacing: '0.8',
                  transition: 'color 0.3s ease-out',
                  '&:hover': {
                    color: '#ffd400',
                    textDecoration: 'underline',
                  },
                }}
              >
                {item.headertext}
                <ListItemText
                  style={{
                    height: '1px',
                    backgroundColor: '#242424',
                    border: 'none',
                    margin: '20px 0 5px',
                    textDecorationStyle: 'solid',
                  }}
                />
              </ListItemText>
            ))
          ) : (
            <Box sx={{ padding: '14px 20px' }}>
              {[...Array(5)].map((_, index) => (
                <Skeleton
                  key={index}
                  variant="text"
                  sx={{ fontSize: '2rem', margin: '10px 0' }}
                />
              ))}
            </Box>
          )}
        </List>
      </Grid>
    </Grid>
  )
}

export default HomeFrstRowScndCol
