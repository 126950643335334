import React, { useState } from 'react'
import {
  Alert,
  AlertTitle,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useData } from '../../../DataContext/DataContext'
import {
  fetchDeleteCategory,
  fetchDeleteAuthor,
  fetchTrasnsferAuthorArticles,
} from '../../../DataContext/DataService'
import AuthorDelete from '../Author/AuthorDelete'

function DeleteButton({ id, name }) {
  const {
    cardDelete,
    setCardDeleteState,
    setCategories,
    setAuthors,
    enquiryDelete,
    setEnquiry,
    setCards,
  } = useData()
  const [error, setError] = useState(false)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [selectedAuthor, setSelectedAuthor] = useState(null)

  const deleteFunctions = {
    article: async () => {
      return await cardDelete(id)
    },
    category: async () => await fetchDeleteCategory(id),
    author: async () => {
      await fetchTrasnsferAuthorArticles(id, selectedAuthor)

      return await fetchDeleteAuthor(id)
    },
    enquiry: async () => await enquiryDelete(id),
  }

  const stateUpdateFunctions = {
    article: () => {
      setCards((prevCard) => prevCard.filter((card) => card._id !== id))
    },
    category: () => {
      setCategories((prevCategories) =>
        prevCategories.filter((category) => category._id !== id)
      )
    },
    author: () => {
      setAuthors((prevAuthors) =>
        prevAuthors.filter((author) => author._id !== id)
      )
    },
    enquiry: () => {
      setEnquiry((prevEnquiry) =>
        prevEnquiry.filter((enquiry) => enquiry._id !== id)
      )
    },
  }

  const handleDelete = async () => {
    setLoading(true)
    let response = null
    try {
      const deleteFunction = deleteFunctions[name]
      if (deleteFunction) {
        response = await deleteFunction()
        console.log('response after delete ', response)
      }

      if (response.message) {
        setMessage(`${name} deleted`)
        console.log(`Response true for ${name}, message: ${message}`)
        const updateStateFunction = stateUpdateFunctions[name]
        if (updateStateFunction) {
          updateStateFunction()
        }
        setError(false)
      } else {
        setMessage(`Failed to delete ${name}`)
        setError(true)
      }
    } catch (err) {
      console.log('Delete error', err)
      setMessage(`Failed to delete ${name}`)
      setError(true)
    } finally {
      setLoading(false)
      setSnackbarOpen(true)
      setConfirmDialogOpen(false)
    }
  }

  const handleCloseSnackbar = () => {
    setError(false)
    setSnackbarOpen(false)
  }

  const handleConfirmOpen = () => {
    setConfirmDialogOpen(true)
  }

  const handleConfirmClose = () => {
    setConfirmDialogOpen(false)
  }

  return (
    <>
      <DeleteIcon onClick={handleConfirmOpen} sx={{ cursor: 'pointer' }} />
      <Dialog
        open={confirmDialogOpen}
        onClose={handleConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {name === 'author'
            ? 'Select author to transfer the articles'
            : 'Confirm Deletion'}
        </DialogTitle>
        <DialogContent>
          {name === 'author' ? (
            <AuthorDelete id={id} onAuthorSelect={setSelectedAuthor} />
          ) : (
            <Alert severity="warning">
              <AlertTitle>
                Are you sure you want to delete this {name}?
              </AlertTitle>
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            color="error"
            autoFocus
            disabled={name === 'author' && !selectedAuthor}
          >
            Yes, Delete
            {loading && <CircularProgress size={24} sx={{ marginLeft: 2 }} />}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={error ? 'error' : 'success'}
        >
          <AlertTitle>{error ? 'Error' : 'Success'}</AlertTitle>
          {message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default DeleteButton
