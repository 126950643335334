import {
  Alert,
  Backdrop,
  Box,
  Card,
  CardHeader,
  CardMedia,
  CircularProgress,
  Grid,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'

import { SaaSButton } from '../ThemeCust'
// import AmazingTeam from './AmazingTeam'
import AmazingTeam from './AmazingTeamV2'
import SubscribeCardAlike from '../HomeCompCont/SubscribeCard'
import { fetchEmailSubscription } from '../DataContext/DataService'
import { MetaDescriptionSetter } from '../../utils/MetaDescriptionSetter'
import AddAuthor from '../Admin/Table/Author/AddAuthor'

const fontStyle = {
  fontFamily: 'Open Sans, sans-serif',
  fontSize: '14px',
  lineHeight: '24px',
  textSizeAadjust: '100%',
  color: '#f3f0f0ac',
}
const inputStyle = {
  '& .MuiOutlinedInput-root': {
    borderRadius: '5px',
    '&.Mui-focused': {
      color: 'black',
    },
    '& fieldset': {
      borderColor: 'black',
    },
    '&:hover fieldset': {
      borderColor: 'yellow',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'yellow',
    },
  },
  textAlign: 'center', // Centering text within TextField
}

function AboutUs() {
  const [input, setInput] = useState('')
  const [addAuthor, setAddAuthor] = useState(false)
  const [loading, setLoading] = useState(false)
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  })
  const submitHandler = async (e) => {
    e.preventDefault()
    setLoading(true)
    // console.log('Form submitted with email:', input)
    try {
      const response = await fetchEmailSubscription(input)
      // console.log('response from subscription', response)
      setSnackbar({
        open: true,
        message: response,
        severity: 'success',
      })
      setInput('')
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.message,
        severity: 'error',
      })
    } finally {
      setLoading(false)
    }
  }
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false })
  }
  return (
    <>
      <MetaDescriptionSetter pageName={'about us'} />
      {!addAuthor ? (
        <Paper elevation={0} sx={{ backgroundColor: '#262320' }}>
          <Card sx={{ width: '100%', position: 'relative' }}>
            <CardMedia
              component="img"
              image="https://assets-global.website-files.com/5f0a4c1cde4cddac7d2ad3b4/5f0d1842cf63ccc11bf0aeb4_Advertise_With_Us-HeaderImage.jpg"
              sx={{
                height: { xs: '200px', sm: '300px', md: '350px' },
                marginTop: '30px',
              }}
            />
            <Typography
              variant="h2"
              fontWeight={700}
              sx={{
                position: 'absolute',
                top: '70%',
                left: '10%',

                transform: 'translateY(-50%)',
                color: '#ffd400',
                textAlign: 'left',
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
                fontSize: { xs: '24px', sm: '32px', md: '40px' },
                padding: '0 10px',
              }}
            >
              About Us
            </Typography>
          </Card>

          <Grid container sx={{ padding: '80px 32px 32px 80px' }}>
            <Grid item xs={12} sm={3}>
              <SubscribeCardAlike header={' Our Mission'} formOn={false} />
            </Grid>

            <Grid item xs={12} sm={8}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={fontStyle}>
                    What we see in the market when it comes to SaaS startups and
                    companies is a rush for becoming bigger and broader company.
                    We at SaaS Journal firmly believe that innovation happens at
                    the smaller companies cos that is where the hunger is. SaaS
                    Journal is an attempt to bring in all kinds of voices within
                    the SaaS space to the forefront. We don't care what your
                    last raise was or
                  </Typography>
                  <img
                    src="https://assets-global.website-files.com/5f0a4c1cde4cddac7d2ad3b4/60f648f06e4ad586254082bd_android-chrome-512x512.png"
                    style={{ maxWidth: '65px', marginTop: '30px' }}
                    alt="aaset-global"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={fontStyle}>
                    what unicorn or decacorn status your company has achieved.
                    If you have an innovative solution and its improving the
                    world in a positive manner we want to write about it and
                    give it wider exposure. The attempt is give voice to the
                    smaller companies who might be looking for a place to talk
                    about what is unique and why they are different.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              <Card variant="outlined" sx={{ backgroundColor: '#f3f0f0ac' }}>
                <CardHeader
                  title={
                    <Box>
                      <Typography
                        variant="h4"
                        sx={{
                          fontSize: '18px',
                          fontWeight: '700',
                          lineHeight: '28px',
                          textAlign: 'center',
                        }}
                      >
                        <p>Join Our Newsletter and Get the Latest</p> Posts to
                        Your Inbox
                      </Typography>
                      <form onSubmit={submitHandler}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: 2,
                          }}
                        >
                          <TextField
                            id="email"
                            value={input}
                            type="email"
                            label="Email"
                            variant="outlined"
                            onChange={(e) => setInput(e.target.value)}
                            sx={{
                              ...inputStyle,
                              width: '30%',
                            }}
                          />
                          <SaaSButton
                            type="submit"
                            sx={{
                              marginLeft: '5px',
                              borderRadius: '10px',
                              textTransform: 'none',
                              fontWeight: 700,
                              padding: '12px 24px',
                            }}
                          >
                            Subscribe
                          </SaaSButton>
                        </Box>
                      </form>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography
                          sx={{
                            textAlign: 'center', // Center the text
                            marginLeft: {
                              xs: '-10px',
                              sm: '-20px',
                              md: '-100px',
                            },
                            marginTop: 2,
                          }}
                        >
                          No spam ever. Read our{' '}
                          <a
                            href="/privacy-policy"
                            style={{
                              color: '#e4ec0bd6',
                              textDecoration: 'none',
                              borderBottom: '1px solid transparent',
                            }}
                            onMouseEnter={(e) =>
                              (e.target.style.borderBottom = '1px solid')
                            }
                            onMouseLeave={(e) =>
                              (e.target.style.borderBottom =
                                '1px solid transparent')
                            }
                          >
                            Privacy Policy
                          </a>
                        </Typography>
                      </Box>
                    </Box>
                  }
                />
              </Card>
            </Grid>

            <Grid item xs={1}></Grid>
          </Grid>
          <AmazingTeam addAuthor={addAuthor} setAddAuthor={setAddAuthor} />
        </Paper>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              paddingTop: 12,
              backgroundColor: '#262320',

              paddingLeft: { md: 25, sm: 18 },
            }}
          >
            <AddAuthor authorCreated={setAddAuthor} author={addAuthor} />
          </Box>
        </Box>
      )}

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default AboutUs
