import React, { useState, useEffect } from 'react'
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Grid,
  Typography,
} from '@mui/material'
import ViewArticles from './Table/Article/ViewArticles'
import { useData } from '../DataContext/DataContext'
import ArticleCreate from './Table/Article/ArticleCreate'
import ArticleEdit from './Table/Article/ArticleEdit'
import Authors from './Table/Author/Authors'
import Categories from './Table/Category/Categories'
import Metadescriptions from './Table/Metadescription/Metadescriptions'
import LogIn from './LogIn'
import DashBoard from './Table/Dashboard/DashBoard'
import AuthorEdit from './Table/Author/AuthorEdit'
import EnqTable from './Table/Enquiry/EnqTable'

function AdminPanelV2() {
  const {
    logged,
    cardEditState,
    handleTabClick,
    selectedTab,
    AdminCurrentCreation,
    authorEditState,
  } = useData()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Simulate a loading delay
    const timer = setTimeout(() => {
      setLoading(false)
    }, 1000) // Adjust the delay as needed

    return () => clearTimeout(timer)
  }, [])

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        backgroundColor: !logged.state ? '#f3f0f0ac' : '#262320',
        marginTop: '73px',
        // padding: '10px 5px', // Adjusted padding to reduce left and right padding
        PaddingTop: '10px',
        paddingLeft: { md: 2 },
      }}
    >
      {logged.state && (
        <Grid container spacing={2} justifyContent="center">
          <Grid
            item
            xs={12}
            sm={4}
            md={2}
            sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            {/* for the index text of admin  */}
            <Paper
              elevation={10}
              sx={{
                backgroundColor: '#262320',
                marginBottom: '10px',
                borderRadius: '2px',
                width: { lg: '100%', md: '250px', sm: '100%', xs: '100%' },
                // marginLeft: 2,
                paddingLeft: { lg: '0px', md: 1, sm: '0px', sx: '0px' },
                // Take full width on smaller screens
                // maxWidth: '300px', // Max width 300px on small screens and up
                // '@media (min-width:600px)': {
                // maxWidth: 'auto', // Max width 350px on medium screens and up
                // },
              }}
            >
              <List>
                {AdminCurrentCreation.map((text) => (
                  <ListItem key={text} sx={{ width: '100%' }}>
                    <ListItemButton
                      sx={{
                        backgroundColor: '#7777',
                        color: '#ffd400',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                        whiteSpace: 'normal', // Allow text to wrap
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        textAlign: 'center', // Center text
                      }}
                      selected={selectedTab === text}
                      onClick={() => handleTabClick(text)}
                    >
                      <Typography>{text}</Typography>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={8} md={10} sx={{ width: '100%' }}>
            {selectedTab === 'Create Article' && !cardEditState && (
              <ArticleCreate />
            )}
            {selectedTab === 'View Articles' && !cardEditState && (
              <ViewArticles />
            )}
            {selectedTab === 'Dashboard' && !cardEditState && <DashBoard />}
            {selectedTab === 'Categories' && !cardEditState && <Categories />}
            {selectedTab === 'Metadescriptions' && !cardEditState && (
              <Box sx={{ maxWidth: '100%', overflowX: 'auto' }}>
                <Metadescriptions />
              </Box>
            )}
            {selectedTab === 'Authors' &&
              !cardEditState &&
              !authorEditState && <Authors />}
            {selectedTab === 'Enquries' && !cardEditState && <EnqTable />}
            {cardEditState && <ArticleEdit />}
            {authorEditState && <AuthorEdit />}
          </Grid>
        </Grid>
      )}
      {!logged.state && (
        <Box sx={{ color: '#f3f0f0ac' }}>
          <LogIn />
        </Box>
      )}
    </Box>
  )
}

export default AdminPanelV2
