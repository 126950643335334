import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Link,
  Paper,
} from '@mui/material'
import React, { useState } from 'react'

import { useData } from '../DataContext/DataContext'
import { truncateText } from '../CardBuilder/TextTruncate'
import { useNavigate } from 'react-router-dom'
import { fetchCardClick } from '../DataContext/DataService'

export function CardArryMightLike({
  badge,
  headerText,
  backgrndImg,
  index,
  handleHover,
  truncateValue = 100,
  textEnabled = false,
  text,
  id,
  slug,
}) {
  const navigate = useNavigate()
  const clickHandler = async (id, slug) => {
    // console.log('navigating to: ', id)
    await fetchCardClick(id)
    navigate(`/blog/${slug}`)
  }
  return (
    <Card
      key={id}
      container
      className="custom-card"
      onMouseEnter={() => handleHover}
      sx={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
      }}
    >
      <CardActionArea onClick={() => clickHandler(id, slug)}>
        <CardContent sx={{ display: 'flex' }}>
          <Card sx={{ flexShrink: 0, marginRight: 2 }}>
            <CardMedia
              component="img"
              image={backgrndImg}
              sx={{
                width: 70,
                height: 70,
              }}
            />
          </Card>

          <Paper
            sx={{
              backgroundColor: 'transparent',
              boxShadow: 'none',
              margin: '-4px 0 10px 0',
            }}
          >
            <Link
              underline="none"
              component="button"
              sx={{
                color: 'white',
                fontSize: '14px',
                fontWeight: '600',
                letterSpacing: '1px',
                lineHeight: '24px',
                textAlign: 'left',

                transition: 'color 0.2s ease-out',
                '&:hover': { color: '#dde03d' },
              }}
            >
              {headerText}
            </Link>
            {textEnabled && truncateText(text, truncateValue)}
          </Paper>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

// function MightLike() {
//   const { cards } = useData()
//   const [setActiveCard] = useState()
//   const handleHover = (index) => setActiveCard(index)
//   return (
//     <Card square={true}>
//       <CardContent
//         sx={{
//           backgroundColor: '#262320',
//         }}
//       >
//         {cards.slice(-10, cards.length).map((card, index) => (
//           <CardArryMightLike
//             key={card._id}
//             backgrndImg={card.imagelink}
//             headerText={truncateText(card.headertext, 40)}
//             badge={card.badge}
//             handleHover={handleHover}
//             id={card._id}
//           />
//         ))}
//       </CardContent>
//     </Card>
//   )
// }
function FeaturedPost() {
  const { cards } = useData()
  const [setActiveCard] = useState()
  const handleHover = (index) => setActiveCard(index)

  // Sort the cards by clicks in descending order and take the top 10
  const sortedCards = [...cards]
    .sort((a, b) => b.clicks - a.clicks)
    .slice(0, 12)

  return (
    <Card square={true}>
      <CardContent
        sx={{
          backgroundColor: '#262320',
          marginTop: '-35px',
        }}
      >
        {sortedCards.map((card, index) => (
          <CardArryMightLike
            key={card._id}
            backgrndImg={card.imagelink}
            headerText={truncateText(card.headertext, 40)}
            badge={card.badge}
            handleHover={() => handleHover(index)}
            id={card._id}
            slug={card.slug}
          />
        ))}
      </CardContent>
    </Card>
  )
}
export default FeaturedPost
