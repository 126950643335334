import React, { useState, useMemo, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useData } from '../DataContext/DataContext'
import { CardBuidlerV2 } from '../CardBuilder/CardBuilderV2'
import {
  Grid,
  Box,
  Card,
  CardMedia,
  Typography,
  Paper,
  Pagination,
  Stack,
} from '@mui/material'
import SubscribeCardAlike from '../HomeCompCont/SubscribeCard'
import { CategoryGrid } from './CategoryColor'

const ITEMS_PER_PAGE = 4

function CategorySpecificContent() {
  const { categoryName } = useParams()
  const { cards, categories } = useData()
  const navigate = useNavigate()
  const [page, setPage] = useState(1)

  // Memoized filtered cards for the specific category
  const cardsOfCategory = useMemo(
    () => cards.filter((card) => card.category.name === categoryName),
    [cards, categoryName]
  )

  // Memoized paginated cards based on the current page
  const paginatedCards = useMemo(() => {
    const startIndex = (page - 1) * ITEMS_PER_PAGE
    return cardsOfCategory.slice(startIndex, startIndex + ITEMS_PER_PAGE)
  }, [page, cardsOfCategory])

  // Memoized page change handler
  const handlePageChange = useCallback((event, value) => {
    setPage(value)
  }, [])

  // Navigation handler
  const clickToCategory = useCallback(
    (categoryName) => {
      navigate(`/category/${categoryName}`)
    },
    [navigate]
  )

  return (
    <Box
      sx={{
        flexGrow: 1,
        p: { xs: 2, sm: 3, md: 4, lg: 8, xl: 10 },
        backgroundColor: 'white',
      }}
    >
      <Grid container spacing={2} justifyContent="center">
        <CategoryGrid />

        {/* Category Header */}
        <Card sx={{ width: '100%', position: 'relative', mb: 3 }}>
          <CardMedia
            component="img"
            image="https://assets-global.website-files.com/5f0a4c1cde4cddac7d2ad3b4/5f0d1842cf63ccc11bf0aeb4_Advertise_With_Us-HeaderImage.jpg"
            sx={{
              height: { xs: '200px', sm: '300px', md: '350px' },
            }}
          />
          <Typography
            variant="h2"
            fontWeight={700}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '10px',
              transform: 'translateY(-50%)',
              color: '#ffd400',
              textAlign: 'left',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
              fontSize: { xs: '24px', sm: '32px', md: '40px' },
            }}
          >
            {categoryName} Category
          </Typography>
        </Card>

        {/* Cards and Pagination */}
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              {paginatedCards.map((card, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <CardBuidlerV2
                    cardId={card._id}
                    cardImgUrl={card.imagelink}
                    cardHeader={card.headertext}
                    cardText={card.shortdescription}
                    cardBadge={card.category.name}
                    truncateValue={150}
                    truncate={true}
                    cardDate={card.date}
                    animation={true}
                    slug={card.slug}
                    cardHeight="400"
                    lazyLoad={true} // Assuming CardBuilderV2 supports lazy loading
                  />
                </Grid>
              ))}
            </Grid>
            <Stack spacing={2} sx={{ mt: 3 }}>
              <Pagination
                count={Math.ceil(cardsOfCategory.length / ITEMS_PER_PAGE)}
                page={page}
                onChange={handlePageChange}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  '& .MuiPaginationItem-root': {
                    color: 'black',
                  },
                  '& .Mui-selected': {
                    backgroundColor: 'white',
                    color: '#000',
                  },
                }}
              />
            </Stack>
          </Grid>

          {/* Right Column - Category Information */}
          <Grid item xs={12} md={4}>
            <Box sx={{ mt: 3, textAlign: 'center' }}>
              <SubscribeCardAlike
                header={'Categories'}
                formOn={false}
                backgroundColor="white"
              />
              <Box sx={{ mt: 3 }}>
                {categories.map((category, index) => (
                  <Paper
                    onClick={() => clickToCategory(category.name)}
                    key={index}
                    sx={{
                      backgroundColor: category.color,
                      padding: '8px',
                      marginBottom: '8px',
                      color: '#fff',
                      cursor: 'pointer',
                      transition: 'all 0.6s ease-out',
                      '&:hover': {
                        paddingLeft: '30px',
                        backgroundColor: '#333',
                      },
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)',
                        color: 'Black',
                        transition: 'color 0.3s ease-in-out',
                        '&:hover': {
                          color: 'white',
                        },
                      }}
                    >
                      {category.name}
                    </Typography>
                  </Paper>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default CategorySpecificContent
