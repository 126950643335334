import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Snackbar,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useState } from 'react'
import { SaaSButton } from '../../../ThemeCust'
import { fetchCreateMetadescription } from '../../../DataContext/DataService'
import { useData } from '../../../DataContext/DataContext'
import SubscribeCardAlike from '../../../HomeCompCont/SubscribeCard'

const textFieldStyles = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'yellow',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'yellow',
    },
  },
  margin: '-30px 10px 10px 12px',
  width: '100%',
  input: {
    color: 'white',
    fontSize: '14px',
    fontWeight: '500',
  },
}

function CreateMeta({ metaCreated, meta }) {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { setMetaDescription } = useData()
  const [input, setInputs] = useState({})
  const [loading, setLoading] = useState(false)
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  })
  const handleChange = (e) => {
    const { name, value } = e.target
    setInputs((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false })
  }
  const resetInputs = () => {
    // setInputs([...(prevState = null)])
    // console.log('after input reset', input)
  }

  const formSubmitHandler = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      const { name, metadescription } = input
      const response = await fetchCreateMetadescription(name, metadescription)
      if (response) setLoading(false)
      setSnackbar({
        open: true,
        message: 'Meta description created successfully',
        severity: 'success',
      })

      //push the new metadescription
      setMetaDescription((prevMetaDescriptions) => [
        response.newMetaDescription,
        ...prevMetaDescriptions,
      ])
      resetInputs()
      setTimeout(() => {
        metaCreated(false)
      }, 3000)

      console.log('Meta description created successfully', response)
    } catch (error) {
      console.log('Error creating meta description:', error)
      setLoading(false)
      // metaCreated(true)
      if (
        error.response &&
        error.response.data.message === 'Meta description already exists'
      ) {
        setSnackbar({
          open: true,
          message: 'Meta description already exists',
          severity: 'warning',
        })
      } else {
        setSnackbar({
          open: true,
          message: 'Failed to create meta description',
          severity: 'error',
        })
      }
    }
  }

  return (
    <Box
      sx={{
        // p: 3,
        // marginTop: 2,
        // paddingLeft: -1,
        // paddingTop: 5,
        backgroundColor: '#262320',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start  ',
        flexDirection: 'column',
        ml: { md: -4, xs: -3 },
        paddingLeft: '10px',
        paddingRight: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: { sm: 'right', xs: 'right' },
          marginBottom: { sm: '-10px', xs: '10px', lg: '-10px', md: '-10px' },
          marginTop: { sm: '-15px', xs: '-25px' },
          ml: { lg: 3, md: 3, sm: 3, xs: 3 },
        }}
      >
        <SubscribeCardAlike
          formOn={false}
          header={'Add details for metadescription'}
        />
      </Box>
      <form onSubmit={formSubmitHandler}>
        <Grid
          container
          spacing={2}
          padding={isSmallScreen ? 2 : 5}
          justifyContent={isSmallScreen ? 'center' : 'flex-start'}
        >
          <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: 5, mt: -3 }}>
            <TextField
              name="name"
              value={input.name}
              placeholder="Enter page name fow which metadescription will be used"
              type="text"
              variant="outlined"
              sx={textFieldStyles}
              onChange={handleChange}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              name="metadescription"
              value={input.metadescription}
              placeholder="Enter metadescription"
              type="text"
              variant="outlined"
              sx={textFieldStyles}
              onChange={handleChange}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'left' }}>
            <SaaSButton
              type="submit"
              sx={{
                borderRadius: '10px',
                textTransform: 'none',
                fontWeight: 700,
                padding: '12px 24px',
                // marginTop: '20px',
                // marginLeft: '25px',
                marginTop: '5px',
                marginLeft: { lg: 1, md: 1, sm: 1, xs: 1.5 },
              }}
            >
              Submit
            </SaaSButton>
          </Grid>
        </Grid>
      </form>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default CreateMeta
