import React, { useState } from 'react'
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import { truncateText } from './TextTruncate'
import { SaaSButton } from '../ThemeCust'
import TwitterIcon from '@mui/icons-material/Twitter'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import { useNavigate } from 'react-router-dom'
import { useData } from '../DataContext/DataContext'

const iconStyle = {
  width: '30px',
  height: '30px',
  marginLeft: '25px',
  marginRight: '-15px',
  marginBottom: '10px',
  '&:hover': {
    backgroundColor: 'white',
  },
}

export function CardBuilder({
  authorName,
  animation = true,
  cardContentDisplay,
  width,
  square,
  truncate = true,
  truncateValue = 150,
  navigationOn = false,
  buttonShown = true,
}) {
  const { authors } = useData()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)

  const handleClick = () => {
    navigationOn &&
      navigate(`/author-content/${encodeURIComponent(authorName)}`)
  }

  const decodedAuthorName = decodeURIComponent(authorName)
  const authorDetails =
    authors.length > 0 &&
    authors.find((author) => author.name === decodedAuthorName)

  if (!authorDetails) {
    return <div>Author not found</div>
  }

  return (
    <Card
      elevation={0}
      square={square}
      sx={{
        background: '#f3f0f0ac',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <CardActionArea onClick={handleClick}>
        <CardContent sx={{ display: cardContentDisplay }}>
          <div style={{ position: 'relative', height: '300px', width: width }}>
            {loading && (
              <CircularProgress
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 1,
                }}
              />
            )}
            <CardMedia
              component="img"
              image={authorDetails.imagelink}
              style={{
                width: '100%',
                height: '100%',
                transition: 'transform 0.5s ease',
                transform: 'translateX(0)',
                objectFit: 'cover',
                display: loading ? 'none' : 'block',
              }}
              onLoad={() => setLoading(false)}
              onError={() => setLoading(false)}
              {...(animation && {
                onMouseEnter: (e) => {
                  e.currentTarget.style.transform = 'translateX(5px)'
                },
                onMouseLeave: (e) => {
                  e.currentTarget.style.transform = 'translateX(0)'
                },
              })}
            />
          </div>
          <div style={{ marginLeft: '1rem' }}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h6" color={'black'}>
                  {authorDetails.name}
                </Typography>
              </Grid>
              <Grid item>
                {buttonShown && (
                  <SaaSButton
                    sx={{
                      margin: '10px 0px 10px 0px',
                      borderRadius: '10px',
                      textTransform: 'none',
                      fontWeight: 400,
                      padding: '7px 7px',
                    }}
                  >
                    View Posts
                  </SaaSButton>
                )}
              </Grid>
            </Grid>
            <Typography
              variant="caption"
              sx={{ color: 'black', lineHeight: '20px' }}
            >
              {truncate
                ? truncateText(authorDetails.description, truncateValue)
                : authorDetails.description}
            </Typography>
          </div>
        </CardContent>
      </CardActionArea>
      <div>
        <IconButton
          aria-label="Twitter"
          sx={iconStyle}
          onClick={() => window.open(authorDetails.twitter, '_blank')}
        >
          <TwitterIcon sx={{ fontSize: 30 }} />
        </IconButton>
        <IconButton
          aria-label="LinkedIn"
          sx={iconStyle}
          onClick={() => window.open(authorDetails.linkedIn, '_blank')}
        >
          <LinkedInIcon sx={{ fontSize: 30 }} />
        </IconButton>
      </div>
    </Card>
  )
}
