import React, { useState } from 'react'
import {
  TextField,
  Grid,
  Box,
  Typography,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material'
import { SaaSButton } from '../../../ThemeCust'
import axios from 'axios'
import { useData } from '../../../DataContext/DataContext'
import SubscribeCardAlike from '../../../HomeCompCont/SubscribeCard'

const inputStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'yellow',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'yellow',
    },
  },
  textAlign: 'center',
}

const inputPropStyle = {
  style: {
    color: 'white',
    fontSize: '14px',
    fontWeight: '500',
  },
}

function AuthorEdit() {
  const { setAuthors, setSelectedTab, authorEditValue, setAuthorEditState } =
    useData()
  const [input, setInputs] = useState({
    name: authorEditValue.name ? authorEditValue.name : '',
    imagelink: authorEditValue.imagelink ? authorEditValue.imagelink : '',
    description: authorEditValue.description ? authorEditValue.description : '',
    linkedIn: authorEditValue.linkedIn ? authorEditValue.linkedIn : '',
    twitter: authorEditValue.twitter ? authorEditValue.twitter : '',
  })
  const authorId = authorEditValue._id
  const [imagePreview, setImagePreview] = useState(
    authorEditValue.imagelink || null
  )
  const [loading, setLoading] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')

  const handleChange = (e) => {
    const { name, value, files } = e.target

    if (name === 'imagelink' && files.length > 0) {
      const selectedFile = files[0]
      const sanitizedFileName = selectedFile.name.replace(/\s+/g, '_')
      if (!selectedFile.type.startsWith('image/')) {
        alert('Please select an image file.')
        return
      }
      const sanitizedFile = new File([selectedFile], sanitizedFileName, {
        type: selectedFile.type,
      })
      setImagePreview(URL.createObjectURL(selectedFile))

      setInputs((prevState) => ({
        ...prevState,
        [name]: sanitizedFile,
      }))
    } else {
      setInputs((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const formSubmitHandler = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      const formData = new FormData()
      Object.entries(input).forEach(([key, value]) => {
        formData.append(key, value)
      })
      // console.log('formData in author update', formData)
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/authors/${authorId}`,
        formData
      )
      console.log('response of the author update', response.data.data)
      if (response.data.message === 'Author updated') {
        setSnackbarSeverity('success')
        setSnackbarMessage('Author updated successfully!')
        setAuthors((prevAuthors) => [
          ...prevAuthors.filter((a) => a._id !== authorId),
          response.data.data.author,
        ])
        setAuthorEditState(false)
        setInputs({
          name: '',
          imagelink: null,
          description: '',
          linkedIn: '',
          twitter: '',
        })
        setTimeout(() => {
          setSelectedTab('Authors')
        }, 3000)
      } else {
        setSnackbarSeverity('warning')
        setSnackbarMessage(
          'Author update response received, but the author might not have been updated.'
        )
      }
      setSnackbarOpen(true)
    } catch (error) {
      console.log('Error updating author', error)
      setLoading(false)
      if (
        error.response &&
        error.response.data.message === 'Author already exists'
      ) {
        setSnackbarSeverity('warning')
        setSnackbarMessage(
          'Author already exists. Please use a different name.'
        )
      } else {
        setSnackbarSeverity('error')
        setSnackbarMessage('Error updating author. Please try again.')
      }
      setSnackbarOpen(true)
    }
  }

  const clickToCancel = () => {
    setAuthorEditState(false)
    setSelectedTab('Authors')
  }

  return (
    <Box
      sx={{
        p: 3,
        backgroundColor: '#262320',
        mt: 5,
      }}
    >
      <form onSubmit={formSubmitHandler}>
        <Grid container spacing={2} alignItems="left">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              // marginLeft: -43,
              // marginBottom: ,
              marginTop: -12,
            }}
          >
            <SubscribeCardAlike
              formOn={false}
              header={'Edit the author details'}
            />
          </Box>
          <Grid xs={12} sx={{ display: 'flex', marginLeft: '-10px' }}>
            {/* <SubscribeCardAlike formOn={false} header={'Edit Author'} /> */}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              name="name"
              value={input.name}
              placeholder="Enter Author's name"
              type="text"
              variant="outlined"
              sx={{
                ...inputStyle,
                margin: '-30px 10px 10px  10px',
                width: '100%',
              }}
              InputProps={inputPropStyle}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              name="imagelink"
              accept="image/*"
              type="file"
              variant="outlined"
              inputProps={inputPropStyle}
              onChange={handleChange}
              sx={{
                ...inputStyle,
                margin: '-30px 10px 10px  0px',
                width: '100%',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {imagePreview && (
              <Box sx={{ textAlign: 'center', mb: 2 }}>
                <Typography variant="subtitle1" sx={{ color: 'white' }}>
                  Image Preview:
                </Typography>
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{ maxHeight: 250 }}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              name="linkedIn"
              value={input.linkedIn}
              placeholder="Enter Author's LinkedIn link"
              type="text"
              variant="outlined"
              sx={{
                ...inputStyle,
                margin: '20px 10px 10px  10px',
                width: '100%',
              }}
              InputProps={inputPropStyle}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              name="twitter"
              value={input.twitter}
              placeholder="Enter Author's Twitter link"
              type="text"
              variant="outlined"
              sx={{
                ...inputStyle,
                margin: '20px 10px 10px  10px',
                width: '100%',
              }}
              InputProps={inputPropStyle}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <TextField
              name="description"
              value={input.description}
              placeholder="Type in author's description"
              type="text"
              variant="outlined"
              sx={{
                ...inputStyle,
                marginLeft: 1,
                '& textarea': {
                  height: 'auto',
                  minHeight: '20px',
                },
              }}
              multiline
              rows={4}
              fullWidth
              InputProps={inputPropStyle}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'left' }}>
            <SaaSButton
              type="submit"
              sx={{
                borderRadius: '10px',
                textTransform: 'none',
                fontWeight: 700,
                padding: '12px 24px',
                marginTop: '5px',
                marginLeft: 1,
              }}
            >
              Update
            </SaaSButton>
            <SaaSButton
              onClick={clickToCancel}
              sx={{
                borderRadius: '10px',
                textTransform: 'none',
                fontWeight: 700,
                padding: '12px 24px',
                marginTop: '5px',
                marginLeft: 1,
              }}
            >
              Cancel
            </SaaSButton>
          </Grid>
        </Grid>
      </form>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default AuthorEdit
