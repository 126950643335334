import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useData } from '../DataContext/DataContext'
import { CardBuilder } from '../CardBuilder/CardBuilder'
import { CardBuidlerV2 } from '../CardBuilder/CardBuilderV2'
import {
  Grid,
  Box,
  Card,
  CardMedia,
  Typography,
  CircularProgress,
  Stack,
  Pagination,
} from '@mui/material'
import NoContentMessage from '../../utils/MovingDots'

const CARDS_PER_PAGE = 4

function AuthorSpecificContent() {
  const [currentPage, setCurrentPage] = useState(1)
  const { cards, authors } = useData()
  const { authorName } = useParams()
  const decodedAuthorName = decodeURIComponent(authorName)
  console.log('Decoded author name:', decodedAuthorName)

  const author = authors.find((author) => author.name === decodedAuthorName)

  if (!author) {
    console.error(`Author not found: ${decodedAuthorName}`)
    return <div>Author not found</div>
  }

  const cardsOfAuthor = cards.filter(
    (card) => card.author.name === decodedAuthorName
  )

  if (cardsOfAuthor.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          minHeight: '400px',
          marginTop: '100px',
        }}
      >
        <NoContentMessage />
      </Box>
    )
  }

  const indexOfLastCard = currentPage * CARDS_PER_PAGE
  const indexOfFirstCard = indexOfLastCard - CARDS_PER_PAGE
  const currentCards = cardsOfAuthor.slice(indexOfFirstCard, indexOfLastCard)

  const handlePageChange = (event, value) => {
    setCurrentPage(value)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Grid container sx={{ backgroundColor: '#f3f0f0ac', padding: '2px' }}>
        <Card sx={{ width: '100%', position: 'relative' }}>
          <CardMedia
            component="img"
            image="https://assets-global.website-files.com/5f0a4c1cde4cddac7d2ad3b4/5f0d1842cf63ccc11bf0aeb4_Advertise_With_Us-HeaderImage.jpg"
            sx={{
              height: { xs: '200px', sm: '300px', md: '350px' },
              marginTop: '30px',
            }}
          />
          <Typography
            variant="h2"
            fontWeight={700}
            sx={{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              color: '#ffd400',
              textAlign: 'left',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
              fontSize: { xs: '24px', sm: '32px', md: '40px' },
              padding: '0 10px',
            }}
          >
            Post By {decodedAuthorName}
          </Typography>
        </Card>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          marginTop: '30px',
          paddingLeft: '40px',
          paddingRight: '40px',
        }}
      >
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            {currentCards.map((card, index) => (
              <Grid item xs={12} md={6} key={index}>
                <CardBuidlerV2
                  cardId={card._id}
                  cardImgUrl={card.imagelink}
                  cardHeader={card.headertext}
                  cardText={card.shortdescription}
                  cardBadge={card.category.name}
                  truncateValue={120}
                  truncate={true}
                  cardDate={card.date}
                  animation={true}
                  slug={card.slug}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <CardBuilder
            authorName={decodedAuthorName}
            truncate={false}
            buttonShown={false}
            linkedInUrl={author.linkedIn}
            twitterUrl={author.twitter}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '40px',
          borderRadius: '5px',
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        <Stack spacing={2}>
          <Pagination
            count={Math.ceil(cardsOfAuthor.length / CARDS_PER_PAGE)}
            page={currentPage}
            onChange={handlePageChange}
            shape="rounded"
            variant="outlined"
            color="primary"
          />
        </Stack>
      </Box>
    </Box>
  )
}

export default AuthorSpecificContent
