import React from 'react'
import {
  AppBar,
  Grid,
  IconButton,
  Toolbar,
  useMediaQuery,
  useTheme,
  Typography,
  Box,
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import {
  StyleDropDownfrmAppbr,
  SaaSButton,
  ImgLogoComp,
} from '../Components/ThemeCust'
import DrawerCmpnt from './DrawerCmpnt'
import NavLinks from './NavLinks'
import { Login } from '@mui/icons-material'
import LogoutIcon from '@mui/icons-material/Logout'
import { useData } from '../Components/DataContext/DataContext'

function NavDrawer({ links }) {
  const navigate = useNavigate()
  const theme = useTheme()
  const { logged, logOutHanlder, logInHandler } = useData()

  const isBelowMd = useMediaQuery(theme.breakpoints.down('md'))
  const isBelowLg = useMediaQuery(theme.breakpoints.down('lg'))

  function loginStateHandler() {
    logged.state ? logOutHanlder() : logInHandler()
  }

  const clickHandler = () => {
    navigate('/contact-us')
  }

  return (
    <AppBar
      sx={{ ...StyleDropDownfrmAppbr, padding: { lg: '0px 35px 0px 35px' } }}
    >
      <Toolbar width="100%">
        <Grid container alignItems="center" justifyContent="space-between">
          {/* Logo Section */}
          <Grid item xs={2} sm={2} md={1}>
            <IconButton disableRipple component={Link} to={links[0].route}>
              <ImgLogoComp />
            </IconButton>
          </Grid>

          {/* Navigation Links */}
          {!isBelowMd && (
            <Grid
              item
              md={8}
              sx={{
                display: 'flex',
                justifyContent: { lg: 'center', md: 'flex-end' },
              }}
            >
              <NavLinks links={links} />
            </Grid>
          )}

          {/* Action Buttons / Drawer */}
          <Grid
            item
            xs={6}
            sm={8}
            md={2}
            lg={3}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '10px', // Add a gap between Drawer and Promote button
            }}
          >
            {!isBelowLg && (
              <SaaSButton
                onClick={clickHandler}
                color="secondary"
                variant="contained"
                sx={{
                  fontWeight: { md: '300', lg: '400' },
                  letterSpacing: '0.8',
                  fontSize: { md: '14px', lg: '16px' },
                  marginRight: '0', // Remove marginRight for tight alignment
                }}
              >
                Promote Your SaaS
              </SaaSButton>
            )}

            {logged.state && (
              <SaaSButton onClick={loginStateHandler}>
                {logged.state ? <LogoutIcon /> : <Login />}
                <Typography
                  sx={{
                    fontSize: { md: '14px', lg: '16px' },
                    padding: '2px',
                  }}
                >
                  {logged.value}
                </Typography>
              </SaaSButton>
            )}

            {isBelowMd && <DrawerCmpnt links={links} />}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default NavDrawer
